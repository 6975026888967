import { navigate } from '@reach/router'
import { useLocation } from '@reach/router'
import FeedbackForm from 'components/ui/feedback-form/FeedbackForm'
import ErrorModal from 'components/ui/modal/ErrorModal'
import { getVisitorId } from 'components/util/UserId'
import dayjs from 'dayjs'
import { Field, Formik, useFormikContext } from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import { parse, stringify } from 'query-string'
import React, { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { createDogs, resetLoadingStatus, updateChangedStatus } from 'state/actions/dogAction'
import { updateCouponHash, updatePlanHash, updateProductHash } from 'state/actions/hashAction'
import { updateKey } from 'state/actions/keyAction'
import { removeAddons, removeRecipes } from 'state/actions/planAction'
import { getProducts } from 'state/actions/productsAction'
import { DogType, PackNewType, SummaryType } from 'state/types/dogType'
import { DogInfoSchema } from 'utils/yup-validation-schema'
import * as yup from 'yup'
import DogAccordion from './DogAccordion'

import { Cache } from 'aws-amplify'

interface DogsCtxState {
  validateForm: any
}

export const DogsCtx = React.createContext<DogsCtxState>({} as DogsCtxState)

const DogsAlternative = () => {
  const {
    dogInfo,
    coupon,
    summary,
    pack,
    changed,
    loading,
    error,
    errorMessage
  }: {
    dogInfo: DogType
    summary: SummaryType[]
    coupon: any
    pack: any
    changed: boolean
    loading: boolean
    error: boolean
  } = useSelector((state: RootState) => state.dog)

  const { key } = useSelector((state: RootState) => state.key)
  const { planHash, couponHash, productListHash } = useSelector((state: RootState) => state.hash)
  const products = useSelector((state: RootState) => state.products)
  // Get productListHash from product store
  // TODO: Can be updated once product store in place
  const { productListHash: productHash } = products || { productListHash: '' }
  // const { productListHash } = useSelector((state: RootState) => state.products)
  const dispatch = useDispatch()
  const [totalDogs, _setTotalDogs] = useState([1])
  const [showPopup, setShowPopup] = useState(false)
  const location = useLocation()
  const formikRef = useRef<any>()

  const [_userId, setUserId] = useState('')
  const [overrideFirstName, setOverrideFirstName] = useState('')

  let visitorId: string = Cache.getItem('visitorId')
  if (
    [
      '638c084f1f16cf85aed66cf027472004',
      'baefc2443c952ce9109898d9016853d4',
      '696ab977b1e177303a0c0023240ddfdf',
      '814e1afd9f329f4c5aa6e32f9ee9568a',
      '1795d46ba0721e30c39ec35f47e981dd'
    ].includes(visitorId)
  )
    visitorId = ''

  const getUserId = async () => {
    const visitorId = await getVisitorId()
    setUserId(visitorId)
  }

  useEffect(() => {
    if (visitorId) {
      setUserId(visitorId)
    } else {
      getUserId()
    }
  }, [visitorId])

  useEffect(() => {
    dispatch(resetLoadingStatus())
  }, [])

  useEffect(() => {
    if (errorMessage && error) {
      setShowPopup(true)
    }
  }, [errorMessage])

  useEffect(() => {
    // Populates the product store
    // if (!products)
    dispatch(getProducts())
  }, [])

  useEffect(() => {
    const updateKeyAndRedirect = () => {
      dispatch(updateKey('your-details'))
      navigate(`/signup/your-details/${location.search && '?' + stringify(parse(location.search))}`)
    }

    if (changed) {
      if (typeof window.gtag !== 'undefined') {
        if (pack.planLevel) {
          // this updates the ga set (should work, if not, add to script tag in Helmet component)
          window.gtag('set', { dimension4: `${pack.planLevel.toString()}` })
          // this updates the ga send as its an event (may need to change config to event, not sure, try both)
          window.gtag('event', 'Submit', {
            event_category: 'Form',
            event_label: 'Subscription Signup Calculate'
          })
        }

        if (error) {
          window.gtag('event', 'Failure', {
            event_category: 'Form',
            event_label: 'Subscription Signup Calculate'
          })
        }
      }

      if (!error) {
        updateKeyAndRedirect()
      }
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed, error])

  useEffect(() => {
    if (pack && pack.hash && pack.hash !== planHash) {
      dispatch(updatePlanHash(pack.hash))
    }

    if (coupon && coupon.hash && coupon.hash !== couponHash) {
      dispatch(updateCouponHash(coupon.hash))
    }
    if (productHash && productHash !== productListHash) {
      dispatch(updateProductHash(productHash))
    }
  }, [pack, coupon, planHash, couponHash, productHash, productListHash])

  useEffect(() => {
    if (key !== 'dog-details' && key !== 'dog-details-1') {
      navigate(`/signup/${key}/${location.search && '?' + stringify(parse(location.search))}`)
    }
  }, [])

  useEffect(() => {
    if (typeof ttq !== 'undefined') {
      ttq.track('ViewContent')
    }
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'opened_page', {
        page: 'sign_up_dog_details'
      })
    }
  }, [])

  useEffect(() => {
    if (typeof window?.yotpoWidgetsContainer?.initWidget !== 'undefined') {
      window.yotpoWidgetsContainer.initWidgets()
    }
  }, [])

  useEffect(() => {
    const { d: encoded } = parse(location.search)
    if (encoded) setOverrideFirstName(decodeURI(atob(encoded)))
  }, [])

  // TODO: Get this working
  // useEffect(() => {
  //   // Resize page for keyboard
  //   const handleViewportResize = () => {
  //     if (!window.visualViewport) {
  //       console.warn('visualViewport API not supported')
  //       return
  //     }
  //     const viewportHeight = window.visualViewport.height
  //     const container = document.getElementById('container')
  //     if (container) {
  //       container.style.height = `${viewportHeight}px`
  //     }
  //   }

  //   // Attach the resize event listener
  //   window.addEventListener('resize', handleViewportResize)
  //   handleViewportResize()

  //   return () => {
  //     window.removeEventListener('resize', handleViewportResize)
  //   }
  // }, [])

  const handleGetVisitorId = async () => {
    const visitorId = await getVisitorId()
    return visitorId
  }

  // only validates first dog, total dogs is not updated as it validates before updating the number of dogs
  // causing issues when a dog is removed, tries to validate removed dog and fails.
  // dog details page has double validation locally, on each box and on the submit.
  const validationSchema = Object.assign(
    {},
    ...totalDogs.map(i => ({
      [`name${i}`]: DogInfoSchema.fields.name,
      [`dobYear${i}`]: DogInfoSchema.fields.dob,
      [`dobMonth${i}`]: DogInfoSchema.fields.dobMonth.when(`dobYear${i}`, (value, schema) => {
        const todayMinus1Year = dayjs().subtract(1, 'year')
        if (value && Number(value) >= todayMinus1Year.year()) {
          return schema.required('Month of birth is required')
        }
        return schema
      }),
      [`breed${i}`]: DogInfoSchema.fields.breed,
      [`neutered${i}`]: DogInfoSchema.fields.neutered,
      [`weight${i}`]: DogInfoSchema.fields.weight,
      [`physique${i}`]: DogInfoSchema.fields.physique,
      [`exercise${i}`]: DogInfoSchema.fields.exercise.when(`dobYear${i}`, (value, schema) => {
        const todayMinus1Year = dayjs().subtract(1, 'year')
        return !value || Number(value) <= todayMinus1Year.year() ? schema.required('Exercise is required') : schema
      }),
      [`gender${i}`]: DogInfoSchema.fields.gender,
      [`medicalConditionOrAllergies${i}`]: DogInfoSchema.fields.medicalConditionOrAllergies,
      [`fussy${i}`]: DogInfoSchema.fields.fussy
    })),
    {
      email: DogInfoSchema.fields.email,
      marketingOptIn: DogInfoSchema.fields.marketingOptIn
    }
  )

  const getDogDateOfBirth = (year: String, month: String, day: String) => {
    let dateString = ''

    if (year) {
      dateString += year
    }

    if (month) {
      dateString += '-' + month
    }

    if (day) {
      dateString += '-' + day
    }

    return dateString
  }

  return (
    <div id="container" className="relative">
      <div>
        <Formik
          initialValues={Object.assign(
            {},
            ...dogInfo.dogs.map((dog, i) => ({
              [`name${i + 1}`]: dog?.name,
              [`dobYear${i + 1}`]: dog?.dob.split('-')[0],
              [`dobMonth${i + 1}`]: dog?.dob.split('-')[1],
              [`gender${i + 1}`]: dog?.gender,
              [`breed${i + 1}`]: dog?.breed ? dog.breed : [],
              [`neutered${i + 1}`]: typeof dog?.neutered === 'boolean' ? (dog?.neutered ? 'YES' : 'NO') : '',
              [`weight${i + 1}`]: dog?.weight !== 0 ? dog?.weight : '',
              [`physique${i + 1}`]: dog?.physique,
              [`exercise${i + 1}`]: dog?.exercise,
              [`medicalConditionOrAllergies${i + 1}`]:
                typeof dog?.medicalConditionOrAllergies === 'boolean'
                  ? dog?.medicalConditionOrAllergies
                    ? 'YES'
                    : 'NO'
                  : '',
              [`fussy${i + 1}`]: dog?.fussy,
              [`unknownBreed${i + 1}`]: typeof dog?.unknownBreed === 'boolean' ? (dog?.unknownBreed ? true : false) : ''
            })),
            {
              email: dogInfo.customer.email,
              marketingOptIn:
                typeof dogInfo.customer.marketingOptIn === 'boolean' ? dogInfo.customer.marketingOptIn : true
            }
          )}
          validationSchema={yup.object().shape(validationSchema)}
          innerRef={formikRef}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)
            if (typeof window.gtag !== 'undefined') {
              window.gtag('event', 'calculate_plan', {
                page: 'sign_up_dog_details'
              })
            }

            if (typeof ttq !== 'undefined') {
              ttq.track('SubmitForm')
            }

            const dogs: any = []
            ;[1, 2, 3, 4, 5].forEach(i => {
              if (
                values[`name${i}`] &&
                values[`weight${i}`] &&
                values[`dobYear${i}`] &&
                values[`exercise${i}`] &&
                values[`physique${i}`] &&
                values[`breed${i}`] &&
                values[`neutered${i}`] &&
                values[`medicalConditionOrAllergies${i}`] &&
                values[`gender${i}`]
              ) {
                dogs.push({
                  name: values[`name${i}`],
                  weight:
                    typeof values[`weight${i}`] === 'string' ? parseInt(values[`weight${i}`]) : values[`weight${i}`],
                  dob: getDogDateOfBirth(values[`dobYear${i}`], values[`dobMonth${i}`], values[`dobDay${i}`]),
                  exercise: values[`exercise${i}`],
                  physique: values[`physique${i}`],
                  breed: values[`breed${i}`],
                  neutered: values[`neutered${i}`] === 'YES',
                  gender: values[`gender${i}`],
                  medicalConditionOrAllergies: values[`medicalConditionOrAllergies${i}`] === 'YES',
                  fussy: values[`fussy${i}`],
                  unknownBreed: values[`unknownBreed${i}`] === true
                })
              }
            })

            dispatch(removeRecipes())
            dispatch(removeAddons())

            dispatch(
              createDogs({
                customer: {
                  email: '',
                  marketingOptIn: values.marketingOptIn
                },
                dogs,
                visitorId: await handleGetVisitorId()
              })
            )

            setSubmitting(false)

            if (errorMessage) {
              console.error(JSON.stringify(errorMessage, null, 2))
            }
          }}
        >
          {props => {
            return (
              <>
                <FormikErrorFocus
                  formik={useFormikContext()}
                  offset={0}
                  align={'middle'}
                  focusDelay={1000}
                  ease={'linear'}
                  duration={1000}
                />
                <DogsCtx.Provider
                  value={{
                    validateForm: props.validateForm
                  }}
                >
                  <DogAccordion formikProps={props} maxItems={5} overrideFirstName={overrideFirstName} />
                </DogsCtx.Provider>
              </>
            )
          }}
        </Formik>
      </div>
      {errorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup?.message || errorMessage?.message}
          url={errorMessage?.popup?.url ?? ''}
          action={errorMessage?.popup?.action ?? ''}
          actionText={errorMessage?.popup?.actionText ?? ''}
        />
      )}
      {/* <FeedbackForm source={'signup'} /> */}
    </div>
  )
}

export default DogsAlternative
