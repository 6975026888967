import dayjs from 'dayjs'
import { Field } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { DogsCtx } from '.'

interface DogAccordionItemProps {
  isOpened: boolean
  item: number
  formikProps: {
    errors: any
    touched: any
    setFieldValue: any
    values: any
  }
  index: number
  handleSetItems: (opt: string, item?: number) => void
  maxItems: number
  setLastOpened: (isOpened: boolean) => void
  length: any
  overrideFirstName?: string
}

const scrollEnabled = false

const DogAccordionItem: React.FC<DogAccordionItemProps> = ({
  isOpened,
  item,
  formikProps,
  index,
  handleSetItems,
  maxItems,
  setLastOpened,
  length,
  overrideFirstName
}) => {
  const months = [
    {
      value: '01',
      month: 'January'
    },
    {
      value: '02',
      month: 'February'
    },
    {
      value: '03',
      month: 'March'
    },
    {
      value: '04',
      month: 'April'
    },
    {
      value: '05',
      month: 'May'
    },
    {
      value: '06',
      month: 'June'
    },
    {
      value: '07',
      month: 'July'
    },
    {
      value: '08',
      month: 'August'
    },
    {
      value: '09',
      month: 'September'
    },
    {
      value: '10',
      month: 'October'
    },
    {
      value: '11',
      month: 'November'
    },
    {
      value: '12',
      month: 'December'
    }
  ]
  const [collapseOpen, setCollapseOpen] = useState(isOpened)
  const [rotate, setRotate] = useState(isOpened)
  const [collapseStyle, setCollapseStyle] = useState<undefined | number>(undefined)
  const [animation, setAnimation] = useState(false)
  const [showRemoveBtn, setShowRemoveBtn] = useState(false)
  const collapseRef = useRef<HTMLDivElement>(null)
  const [opacityZero, setOpacityZero] = useState(false)
  const [dogName, setDogName] = useState(formikProps.values[`name${index + 1}`])
  const todayMinus1Year = dayjs().subtract(1, 'year')

  const dogsCtx = useContext(DogsCtx)

  let typingTimer: any
  const doneTypingInterval = 1000

  const openAnimation = () => {
    setShowRemoveBtn(true)
    handleSetItems('ADD')
    if (!collapseOpen && collapseStyle === undefined) {
      setCollapseStyle(0)
    }
    setCollapseOpen(true)
    setTimeout(function () {
      setCollapseStyle(collapseRef.current?.scrollHeight)
    }, 10)
    setTimeout(function () {
      setAnimation(false)
    }, 310)

    if (index === maxItems - 1) setLastOpened(true)
  }

  const closeAnimation = () => {
    let timeOutTime = 0
    if (collapseOpen && collapseStyle === undefined) {
      setCollapseStyle(collapseRef.current?.scrollHeight)
      timeOutTime = 10
    }
    setTimeout(function () {
      setCollapseStyle(0)
    }, timeOutTime)
    setTimeout(function () {
      setAnimation(false)
      setCollapseOpen(false)
    }, 310)

    if (index === maxItems - 1) setLastOpened(false)
  }

  const startAnimation = React.useCallback(() => {
    setOpacityZero(false)
    if (!animation) {
      setAnimation(true)
      setRotate(!rotate)
      if (collapseOpen) {
        closeAnimation()
      } else {
        openAnimation()
      }
    }
    dogsCtx.setTotalDogs(curr => [...new Set([...curr, index + 1])])
  }, [index, length])

  useEffect(() => {
    dogsCtx.addDogFunc.current = startAnimation
  }, [startAnimation])

  const resetForm = () => {
    formikProps.setFieldValue([`name${index + 1}`], '')
    formikProps.setFieldValue([`dobYear${index + 1}`], '')
    formikProps.setFieldValue([`dobMonth${index + 1}`], '')
    formikProps.setFieldValue([`gender${index + 1}`], '')
    formikProps.setFieldValue([`neutered${index + 1}`], '')
    formikProps.setFieldValue([`ageStage${index + 1}`], '')
    formikProps.setFieldValue([`weight${index + 1}`], '')
    formikProps.setFieldValue([`physique${index + 1}`], '')
    formikProps.setFieldValue([`exercise${index + 1}`], '')
    formikProps.setFieldValue([`medicalConditionOrAllergies${index + 1}`], '')
    setDogName('')
  }

  const removeAccordion = () => {
    closeAnimation()
    dogsCtx.setIsDisabled(true)
    setTimeout(function () {
      resetForm()
      handleSetItems('REMOVE', item)
      dogsCtx.setIsDisabled(false)
    }, 1100)
    setTimeout(function () {
      setOpacityZero(true)
    }, 800)
    dogsCtx.setTotalDogs(curr => curr.filter(item => item !== index + 1))
  }

  useEffect(() => {
    if (!isOpened && !formikProps.values[`name${index + 1}`]) {
      setCollapseStyle(collapseRef.current?.scrollHeight)
      setRotate(false)
      setTimeout(function () {
        setCollapseStyle(0)
      }, 10)
      setTimeout(function () {
        setAnimation(false)
        setCollapseOpen(false)
      }, 310)
    }
  }, [isOpened])

  useEffect(() => {
    if (overrideFirstName) {
      console.log({ overrideFirstName })

      formikProps.setTouched({ [`name1`]: true })
      clearTimeout(typingTimer)
      formikProps.setFieldValue([`name1`], overrideFirstName)
      // typingTimer = setTimeout(() => {
      let dogName = overrideFirstName
      if (!/^[A-Z]/.test(dogName)) {
        dogName = dogName.charAt(0).toUpperCase() + dogName.slice(1)
        formikProps.setFieldValue([`name1`], dogName)
      }
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'entered_dog_name', {
          page: 'sign_up_dog_details',
          dog_name: dogName
        })
      }

      setDogName(dogName)
      // let nextElement = document.querySelector('.gender-group1')
      // if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
      // }, doneTypingInterval)
    }
  }, [overrideFirstName])

  useEffect(() => {
    if (formikProps.values[`name${index + 1}`]) {
      openAnimation()
    }
  }, [])

  const todayDate = dayjs().format('DD/MM/YYYY')
  const currentDate = todayDate.split('/')

  return (
    <div className={`${opacityZero && 'opacity-0'} transition-all`}>
      {item !== 0 && collapseOpen && (
        <div className="relative my-4 w-full md:hidden">
          <StaticImage src="../../../../images/dogdivider.svg" alt="Divider image between dogs" />
          <div style={{ height: '80%' }} className="absolute right-0 left-0 top-0 bottom-0 flex flex-col">
            <h2 className="m-auto w-full text-center text-2xl md:text-4xl">Pup #{index + 1}</h2>
            {item !== 0 && showRemoveBtn && (
              <button
                type="button"
                className="scale-animation m-auto w-fit cursor-pointer rounded-full border border-white py-2 px-4 text-center text-white"
                onClick={removeAccordion}
              >
                Remove this pup
              </button>
            )}
          </div>
        </div>
      )}
      {item !== 0 && collapseOpen && (
        <div className="relative my-8 hidden w-full md:block">
          <StaticImage src="../../../../images/dogdivider-desktop.svg" alt="Divider image between dogs" />
          <div style={{ height: '90%' }} className="absolute right-0 left-0 top-0 bottom-0 flex flex-col">
            <h2 className="m-auto w-full text-center text-2xl md:text-4xl">Pup #{index + 1}</h2>
            <p className="w-full text-center">You can add up to 5 puppers onto your plan.</p>
            <p className="w-full text-center">Simply add each one and then calculate your plan to continue</p>
            {item !== 0 && showRemoveBtn && (
              <button
                type="button"
                className="scale-animation m-auto w-fit cursor-pointer rounded-full border border-white py-2 px-4 text-center text-white"
                onClick={removeAccordion}
              >
                Remove this pup
              </button>
            )}
          </div>
        </div>
      )}
      <div
        className={`transition-all duration-300 ease-in-out ${collapseOpen ? 'block' : 'hidden'}`}
        style={{
          height: 'auto'
        }}
        ref={collapseRef}
      >
        <div className="mx-auto grid grid-cols-1 gap-y-36 px-4 sm:grid-cols-1 sm:gap-x-8 md:w-4/5">
          {/* What’s Your Dog’s Name? */}
          <div
            className={`relative ${
              (formikProps.values[`name${index + 1}`] || formikProps.values[`gender${index + 1}`]) &&
              `dog-details-line-1`
            } animate__animated animate__fadeInUp name-group${
              index + 1
            } w-full items-center justify-center text-center md:w-1/2 md:justify-center md:justify-self-start`}
          >
            <StaticImage width={75} src="../../../../images/dog-details/signup-dog-details-1.svg" alt="Question 1" />
            <label htmlFor="first-name" className="mb-2 flex flex-col">
              <span className="h3 mb-4 text-2xl md:text-3xl">Who are we feeding?</span>
              <span className="font-third mb-4 block text-4xl">We like to know who we'll be cooking for</span>
              <Field
                data-testid="dogName"
                type="text"
                id={`name${index + 1}`}
                name={`name${index + 1}`}
                className="block w-full self-center rounded-full border-2 border-secondary bg-white py-3 px-4 text-center drop-shadow-lg focus:border-highlight focus:ring-highlight sm:w-56"
                placeholder="Enter your dog's name"
                onChange={e => {
                  formikProps.setTouched({ [`name${index + 1}`]: true })
                  clearTimeout(typingTimer)
                  formikProps.setFieldValue([`name${index + 1}`], e.target.value)
                  typingTimer = setTimeout(() => {
                    let dogName = e.target.value
                    if (!/^[A-Z]/.test(dogName)) {
                      dogName = dogName.charAt(0).toUpperCase() + dogName.slice(1)
                      formikProps.setFieldValue([`name${index + 1}`], dogName)
                    }
                    setDogName(dogName)
                    let nextElement = document.querySelector('.gender-group' + (index + 1))
                    if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                  }, doneTypingInterval)
                }}
                onBlur={() => {
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'entered_dog_name', {
                      page: 'sign_up_dog_details',
                      dog_name: dogName
                    })
                  }
                }}
              />
            </label>
            {formikProps.touched[`name${index + 1}`] && formikProps.errors[`name${index + 1}`] ? (
              <div className="text-center text-xs text-red-500">{formikProps.errors[`name${index + 1}`]}</div>
            ) : null}
          </div>
          {/* Is Your Dog Male/Female */}
          <div
            role="group"
            aria-labelledby="gender-group"
            className={`${
              formikProps.values[`name${index + 1}`] || formikProps.values[`gender${index + 1}`]
                ? 'animate__animated animate__fadeInUp'
                : 'hidden'
            } relative ${
              (formikProps.values[`gender${index + 1}`] || formikProps.touched[`gender${index + 1}`]) &&
              `dog-details-line-2`
            } gender-group${index + 1} w-full justify-center text-center md:w-1/2 md:justify-self-end`}
          >
            <StaticImage
              className="bg-white"
              width={75}
              src="../../../../images/dog-details/signup-dog-details-2.svg"
              alt="Question 2"
            />
            <h4 className="bg-white pb-4 text-2xl md:text-3xl">Is {dogName ? dogName : 'your dog'} a boy or girl? </h4>
            <span className="font-third mb-4 block bg-white text-4xl">Boy and girl pups need different calories</span>
            <label
              tabIndex={0}
              onClick={_e => {
                let nextElement = document.querySelector('.neutered-group' + (index + 1))
                nextElement?.addEventListener('animationend', () => {
                  window.setTimeout(() => {
                    if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                  }, 1000)
                })
              }}
              onChange={e => {
                formikProps.setTouched({ [`gender${index + 1}`]: true })
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'selected_gender', {
                    page: 'sign_up_dog_details',
                    gender: e.target.value
                  })
                }

                formikProps.handleChange(e)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  formikProps.setTouched({ [`gender${index + 1}`]: true })
                  formikProps.setFieldValue([`gender${index + 1}`], 'MALE')
                }
              }}
              className={`${
                formikProps.values[`gender${index + 1}`] === 'MALE' ? 'btn-primary-active' : 'btn-primary'
              } btn-toggle-left w-1/3 justify-center sm:w-24`}
            >
              <Field
                data-testid="genderMale"
                id="genderMale"
                type="radio"
                name={`gender${index + 1}`}
                value="MALE"
                className="hidden"
              />
              Boy
            </label>
            <label
              tabIndex={0}
              onClick={_e => {
                let nextElement = document.querySelector('.neutered-group' + (index + 1))
                nextElement?.addEventListener('animationend', () => {
                  window.setTimeout(() => {
                    if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                  }, 1000)
                })
              }}
              onChange={e => {
                formikProps.setTouched({ [`gender${index + 1}`]: true })
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'selected_gender', {
                    page: 'sign_up_dog_details',
                    gender: e.target.value
                  })
                }

                formikProps.handleChange(e)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  formikProps.setTouched({ [`gender${index + 1}`]: true })
                  formikProps.setFieldValue([`gender${index + 1}`], 'FEMALE')
                }
              }}
              className={`${
                formikProps.values[`gender${index + 1}`] === 'FEMALE' ? 'btn-primary-active' : 'btn-primary'
              } btn-toggle-right w-1/3 justify-center sm:w-24`}
            >
              <Field
                data-testid="genderFemale"
                id="genderFemale"
                type="radio"
                name={`gender${index + 1}`}
                value="FEMALE"
                className="hidden"
              />
              Girl
            </label>
            {formikProps.touched[`gender${index + 1}`] && formikProps.errors[`gender${index + 1}`] ? (
              <div className="text-xs text-red-500">{formikProps.errors[`gender${index + 1}`]}</div>
            ) : null}
          </div>
          {/* Is Your Dog Neutered/spayed */}
          <div
            role="group"
            aria-labelledby="neutered-group"
            className={`${
              formikProps.values[`gender${index + 1}`] || formikProps.touched[`gender${index + 1}`]
                ? 'animate__animated animate__fadeInUp'
                : 'hidden'
            } relative ${
              (formikProps.values[`neutered${index + 1}`] || formikProps.touched[`neutered${index + 1}`]) &&
              `dog-details-line-3`
            } neutered-group${index + 1} w-full justify-center text-center md:w-1/2 md:justify-self-start`}
          >
            <StaticImage
              className="bg-white"
              width={75}
              src="../../../../images/dog-details/signup-dog-details-3.svg"
              alt="Question 3"
            />
            <h4 className="bg-white pb-4 text-2xl md:text-3xl">
              Is {dogName ? dogName : 'your dog'}{' '}
              {formikProps.values[`gender${index + 1}`] === 'FEMALE'
                ? 'spayed'
                : formikProps.values[`gender${index + 1}`] === 'MALE'
                  ? 'neutered'
                  : 'spayed/neutered'}
              ?
            </h4>
            <span className="font-third block bg-white pb-4 text-4xl">
              {formikProps.values[`gender${index + 1}`] === 'FEMALE' ? `Spayed` : `Neutered`} dogs often need fewer
              calories
            </span>
            <label
              tabIndex={0}
              onClick={_e => {
                let nextElement = document.querySelector('.dob-group' + (index + 1))
                nextElement?.addEventListener('animationend', () => {
                  window.setTimeout(() => {
                    if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                  }, 1000)
                })
              }}
              onChange={e => {
                formikProps.setTouched({ [`neutered${index + 1}`]: true })
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'selected_neutered', {
                    page: 'sign_up_dog_details',
                    neutered: e.target.value
                  })
                }

                formikProps.handleChange(e)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  formikProps.setTouched({ [`neutered${index + 1}`]: true })
                  formikProps.setFieldValue([`neutered${index + 1}`], 'YES')
                }
              }}
              className={`${
                formikProps.values[`neutered${index + 1}`] === 'YES' ? 'btn-primary-active' : 'btn-primary'
              } btn-toggle-left w-1/3 justify-center sm:w-24`}
            >
              <Field
                data-testid="neuteredYes"
                id="neuteredYes"
                type="radio"
                name={`neutered${index + 1}`}
                value="YES"
                className="hidden"
              />
              Yes
            </label>
            <label
              tabIndex={0}
              onClick={_e => {
                let nextElement = document.querySelector('.dob-group' + (index + 1))
                nextElement?.addEventListener('animationend', () => {
                  window.setTimeout(() => {
                    if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                  }, 1000)
                })
              }}
              onChange={e => {
                formikProps.setTouched({ [`neutered${index + 1}`]: true })
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'selected_neutered', {
                    page: 'sign_up_dog_details',
                    neutered: e.target.value
                  })
                }

                formikProps.handleChange(e)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  formikProps.setTouched({ [`neutered${index + 1}`]: true })
                  formikProps.setFieldValue([`neutered${index + 1}`], 'NO')
                }
              }}
              className={`${
                formikProps.values[`neutered${index + 1}`] === 'NO' ? 'btn-primary-active' : 'btn-primary'
              } btn-toggle-right w-1/3 justify-center sm:w-24`}
            >
              <Field
                data-testid="neuteredNo"
                id="neuteredNo"
                type="radio"
                name={`neutered${index + 1}`}
                value="NO"
                className="hidden"
              />
              No
            </label>
            {formikProps.touched[`neutered${index + 1}`] && formikProps.errors[`neutered${index + 1}`] ? (
              <div className="text-xs text-red-500">{formikProps.errors[`neutered${index + 1}`]}</div>
            ) : null}
          </div>
          {/* When Was Your Dog Born */}
          <div
            className={`${
              formikProps.values[`neutered${index + 1}`] || formikProps.touched[`neutered${index + 1}`]
                ? 'animate__animated animate__fadeInUp'
                : 'hidden'
            } relative ${
              ((formikProps.values[`exercise${index + 1}`] === 'GROWINGPUPPY'
                ? formikProps.values[`dobMonth${index + 1}`]
                : formikProps.values[`dobYear${index + 1}`]) ||
                formikProps.values[`weight${index + 1}`]) &&
              `dog-details-line-4`
            } dob-group${index + 1} w-full justify-center text-center md:w-1/2 md:justify-self-end`}
          >
            <div className="mr-2 flex flex-row flex-wrap justify-center">
              <StaticImage
                className="bg-white"
                width={75}
                src="../../../../images/dog-details/signup-dog-details-4.svg"
                alt="Question 4"
              />
              <span className="h4 w-full bg-white pb-4 text-center text-2xl md:text-3xl">
                When was {dogName ? dogName : 'your dog'} born?
              </span>
              <span className="font-third block w-full bg-white pb-4 text-4xl">It's ok if you don't know exactly</span>
              <label className="mb-0 mr-2 flex flex-col">
                {' '}
                <Field
                  data-testid="dobYear"
                  name={`dobYear${index + 1}`}
                  component="select"
                  label="date"
                  id="dobYear"
                  className="block w-full self-center rounded-full border-2 border-secondary bg-white py-3 px-4 text-center drop-shadow-lg focus:border-highlight focus:ring-highlight sm:w-56"
                  onChange={e => {
                    formikProps.setFieldValue([`dobYear${index + 1}`], e.target.value)
                    formikProps.setTouched({ [`dobYear${index + 1}`]: true })
                    if (parseInt(e.target.value) >= todayMinus1Year.year()) {
                      formikProps.setFieldValue([`exercise${index + 1}`], 'GROWINGPUPPY')
                    } else {
                      formikProps.setFieldValue([`exercise${index + 1}`], '')
                      let nextElement = document.querySelector('.weight-group' + (index + 1))

                      nextElement?.addEventListener('animationend', () => {
                        window.setTimeout(() => {
                          if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                        }, 3000)
                      })
                    }

                    if (e.target.value == '') {
                      formikProps.setFieldValue([`dobMonth${index + 1}`], '')
                      formikProps.setFieldValue([`dobDay${index + 1}`], '')
                    }

                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'selected_year', {
                        page: 'sign_up_dog_details',
                        dob_year: e.target.value
                      })
                    }
                  }}
                >
                  <option value="">Year</option>
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                  <option value="2017">2017</option>
                  <option value="2016">2016</option>
                  <option value="2015">2015</option>
                  <option value="2014">2014</option>
                  <option value="2013">2013</option>
                  <option value="2012">2012</option>
                  <option value="2011">2011</option>
                  <option value="2010">2010</option>
                  <option value="2009">2009</option>
                  <option value="2008">2008</option>
                  <option value="2007">2007</option>
                  <option value="2006">2006</option>
                  <option value="2005">2005</option>
                  <option value="2004">2004</option>
                  <option value="2003">2003</option>
                  <option value="2002">2002</option>
                  <option value="2001">2001</option>
                  <option value="2000">2000</option>
                </Field>
                <span className="block text-xs font-medium text-ddGrey">{'Guess if unsure'}</span>
              </label>

              {
                <div className={formikProps.values[`dobYear${index + 1}`] ? 'block' : 'hidden'}>
                  <label className="mr-2 flex flex-col items-center text-sm font-medium text-gray-700 ">
                    <Field
                      data-testid="dobMonth"
                      name={`dobMonth${index + 1}`}
                      component="select"
                      label="date"
                      id="dobMonth"
                      className="block w-full self-center rounded-full border-2 border-secondary bg-white py-3 px-4 text-center drop-shadow-lg focus:border-highlight focus:ring-highlight sm:w-56"
                      onChange={e => {
                        formikProps.setFieldValue([`dobMonth${index + 1}`], e.target.value)
                        formikProps.setTouched({ [`dobMonth${index + 1}`]: true })
                        // When month is entered, check if the year and month is less then 1 year from today
                        if (
                          todayMinus1Year.diff(
                            dayjs(`${formikProps.values[`dobYear${index + 1}`]}-${e.target.value}-01`),
                            'year',
                            true
                          ) <= 0
                        ) {
                          formikProps.setFieldValue([`exercise${index + 1}`], 'GROWINGPUPPY')
                        } else {
                          formikProps.setFieldValue([`exercise${index + 1}`], '')
                        }

                        let nextElement = document.querySelector('.weight-group' + (index + 1))

                        nextElement?.addEventListener('animationend', () => {
                          window.setTimeout(() => {
                            if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                          }, 1000)
                        })

                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'selected_month', {
                            page: 'sign_up_dog_details',
                            dob_month: e.target.value
                          })
                        }
                      }}
                    >
                      <option value="">Month</option>
                      {months?.map((month: any, ind: number) => (
                        <option
                          value={month.value}
                          key={ind}
                          disabled={
                            formikProps.values[`dobYear${index + 1}`] === currentDate[2] &&
                            parseInt(month.value) > parseInt(currentDate[1])
                              ? true
                              : false
                          }
                        >
                          {month.month}
                        </option>
                      ))}
                    </Field>
                    {formikProps.values[`dobYear${index + 1}`] >= todayMinus1Year.year() && (
                      <span className="block text-xs font-medium text-ddGrey">{'Required'}</span>
                    )}
                    {formikProps.values[`dobYear${index + 1}`] < todayMinus1Year.year() && (
                      <span className="block text-xs font-medium text-ddGrey">{'Optional'}</span>
                    )}
                  </label>
                </div>
              }
            </div>
            {formikProps.touched[`dobYear${index + 1}`] &&
            formikProps.errors[`dobYear${index + 1}`] &&
            formikProps.values[`dobYear${index + 1}`] === '' ? (
              <div className="text-center text-xs text-red-500">{formikProps.errors[`dobYear${index + 1}`]}</div>
            ) : null}
            {formikProps.touched[`dobMonth${index + 1}`] &&
            formikProps.errors[`dobMonth${index + 1}`] &&
            !formikProps.values[`dobMonth${index + 1}`] ? (
              <div className="text-center text-xs text-red-500">{formikProps.errors[`dobMonth${index + 1}`]}</div>
            ) : null}
          </div>
          {/* Your Dogs Weight */}
          <div
            className={`${
              (formikProps.values[`exercise${index + 1}`] === 'GROWINGPUPPY'
                ? formikProps.values[`dobMonth${index + 1}`]
                : formikProps.values[`dobYear${index + 1}`]) || formikProps.values[`weight${index + 1}`]
                ? 'animate__animated animate__fadeInUp'
                : 'hidden'
            } relative ${
              (formikProps.values[`weight${index + 1}`] || formikProps.values[`physique${index + 1}`]) &&
              `dog-details-line-5`
            } weight-group${index + 1} w-full justify-center bg-white text-center md:w-1/2 md:justify-self-start`}
          >
            <StaticImage width={75} src="../../../../images/dog-details/signup-dog-details-5.svg" alt="Question 5" />
            <label className="mb-0 flex flex-col items-center">
              <span className="h4 block bg-white pb-4 text-2xl md:text-3xl">
                How much does {dogName ? dogName : 'your dog'} weigh?
              </span>
              <span className="font-third block bg-white pb-4 text-4xl">
                Guess if unsure, you can update this later
              </span>
              <div className="flex flex-row items-center">
                <Field
                  data-testid="weight"
                  name={`weight${index + 1}`}
                  id="weight"
                  type="number"
                  pattern="\d*"
                  className="block w-full self-center rounded-full border-2 border-secondary bg-white py-3 px-4 text-center drop-shadow-lg focus:border-highlight focus:ring-highlight sm:w-56"
                  placeholder="Enter your dog's weight"
                  onChange={(e: any) => {
                    formikProps.setTouched({ [`weight${index + 1}`]: true })
                    if (parseInt(e.target.value) > 0) {
                      formikProps.setFieldValue([`weight${index + 1}`], parseInt(e.target.value))
                    } else {
                      formikProps.setFieldValue([`weight${index + 1}`], '')
                    }

                    clearTimeout(typingTimer)
                    typingTimer = setTimeout(() => {
                      let nextElement = document.querySelector('.physique-group' + (index + 1))
                      if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                    }, doneTypingInterval)
                  }}
                  onBlur={e => {
                    if (typeof window.gtag !== 'undefined') {
                      window.gtag('event', 'entered_weight', {
                        page: 'sign_up_dog_details',
                        weight: e.target.value
                      })
                    }
                  }}
                />{' '}
                <span className="ml-2 text-sm font-medium text-gray-700">kg</span>
              </div>
            </label>
            {/* <span
              className={
                !formikProps.values[`weight${index + 1}`]
                  ? 'hidden'
                  : 'mt-4 flex w-full items-center overflow-hidden rounded-3xl bg-ddBlue'
              }
            >
              {formikProps.values[`weight${index + 1}`] <= 5 && (
                <>
                  <StaticImage className="w-1/3" src="../../../../images/XS.jpg" width={200} alt="Extra Small Dog" />
                  <span className="w-full p-4">
                    <p className="mb-4 font-serif md:text-lg">Less than 5 kg: X-Small Breed</p>
                    <p className="font-third text-3xl">E.g. Chihuahua, Jack Russell, Pomeranian</p>
                  </span>
                </>
              )}
              {formikProps.values[`weight${index + 1}`] > 5 && formikProps.values[`weight${index + 1}`] <= 10 && (
                <>
                  <StaticImage className="w-1/3" src="../../../../images/S.jpg" alt="Small Dog" width={200} />
                  <span className="w-full p-4">
                    <p className="mb-4 font-serif md:text-lg">5-10 kg: Small Breed</p>
                    <p className="text-sm md:text-base">E.g. West Highland Terrier, Bichon Frise, Pug</p>
                  </span>
                </>
              )}
              {formikProps.values[`weight${index + 1}`] > 10 && formikProps.values[`weight${index + 1}`] <= 25 && (
                <>
                  <StaticImage className="w-1/3" src="../../../../images/M.jpg" alt="Medium Dog" width={200} />
                  <span className="w-full p-4">
                    <p className="mb-4 font-serif md:text-lg">10-25 kg: Medium Breed</p>
                    <p className="text-sm md:text-base">E.g. Cocker Spaniel, Border Collie, Springer Spaniel</p>
                  </span>
                </>
              )}
              {formikProps.values[`weight${index + 1}`] > 25 && formikProps.values[`weight${index + 1}`] <= 40 && (
                <>
                  <StaticImage className="w-1/3" src="../../../../images/L.jpg" alt="Large Dog" width={200} />
                  <span className="w-full p-4">
                    <p className="mb-4 font-serif md:text-lg">25-40 kg: Large Breed</p>
                    <p className="text-sm md:text-base">E.g. Labrador, Golden Retriever, German Shepherd</p>
                  </span>
                </>
              )}
              {formikProps.values[`weight${index + 1}`] > 40 && formikProps.values[`weight${index + 1}`] <= 50 && (
                <>
                  <StaticImage className="w-1/3" src="../../../../images/XL.jpeg" alt="Extra Large Dog" width={200} />
                  <span className="w-full p-4">
                    <p className="mb-4 font-serif md:text-lg">40-50 kg: X-Large Breed</p>
                    <p className="text-sm md:text-base">E.g. Malamute, Rottweiler, Deerhound</p>
                  </span>
                </>
              )}
              {formikProps.values[`weight${index + 1}`] > 50 && (
                <>
                  <StaticImage className="w-1/3" src="../../../../images/XXL.jpeg" alt="Extra Extra Large Dog" />
                  <span className="w-full p-4">
                    <p className="mb-4 font-serif md:text-lg">More than 50 kg: XX-Large Breed</p>
                    <p className="text-sm md:text-base">E.g. St. Bernard, Mastiff, Irish Wolfhound</p>
                  </span>
                </>
              )}
            </span> */}
            {formikProps.touched[`weight${index + 1}`] && formikProps.errors[`weight${index + 1}`] ? (
              <div className="text-center text-xs text-red-500">{formikProps.errors[`weight${index + 1}`]}</div>
            ) : null}
          </div>
          {/* What Is Your Dog’s Physique */}
          <div
            role="group"
            aria-labelledby="physique-group"
            className={`${
              formikProps.values[`weight${index + 1}`] || formikProps.values[`physique${index + 1}`]
                ? 'animate__animated animate__fadeInUp'
                : 'hidden'
            } relative ${
              (formikProps.values[`physique${index + 1}`] || formikProps.touched[`physique${index + 1}`]) &&
              `dog-details-line-6`
            } physique-group${index + 1} w-full justify-center text-center md:w-1/2 md:justify-self-end`}
          >
            <StaticImage width={75} src="../../../../images/dog-details/signup-dog-details-6.svg" alt="Question 6" />
            <h4 className="bg-white text-2xl md:text-3xl">What is {dogName ? dogName : 'your dog'}’s physique?</h4>
            <span className="font-third block bg-white pb-4 text-4xl">
              {formikProps.values[`physique${index + 1}`] === 'SLIM' && 'A narrow waistline and ribs on show'}
              {formikProps.values[`physique${index + 1}`] === 'PERFECT' && 'Visible waistline and can feel the ribs'}
              {formikProps.values[`physique${index + 1}`] === 'OVERWEIGHT' &&
                'Waistline not visible and ribs are hard to feel'}
            </span>
            <div className="dog-physique-container flex justify-center bg-white">
              <label
                tabIndex={0}
                onClick={_e => {
                  if (formikProps.values[`exercise${index + 1}`] === 'GROWINGPUPPY') {
                    let nextElement = document.querySelector('.medical-allergies-group' + (index + 1))
                    nextElement?.addEventListener('animationend', () => {
                      window.setTimeout(() => {
                        if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                      }, 1000)
                    })
                  } else {
                    let nextElement = document.querySelector('.exercise-group' + (index + 1))
                    nextElement?.addEventListener('animationend', () => {
                      window.setTimeout(() => {
                        if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                      }, 1000)
                    })
                  }
                }}
                onChange={e => {
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_physique', {
                      page: 'sign_up_dog_details',
                      physique: e.target.value
                    })
                  }

                  formikProps.handleChange(e)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`physique${index + 1}`], 'SLIM')
                  }
                }}
                className={`${
                  formikProps.values[`physique${index + 1}`] === 'SLIM'
                    ? 'btn-primary-active rounded-button'
                    : 'btn-primary rounded-button'
                } dog-image-wrapper m-2 inline-flex w-1/3 cursor-pointer flex-col flex-wrap items-center self-center sm:w-32`}
              >
                <StaticImage
                  objectFit={'contain'}
                  className="dog-physique block h-20 w-full"
                  src="../../../../images/dog-slim-2.svg"
                  alt="Slim Sized Dog"
                />
                <Field
                  data-testid="physique1"
                  id="physique1"
                  type="radio"
                  name={`physique${index + 1}`}
                  value="SLIM"
                  className="hidden"
                />
                <span>Slim</span>
              </label>
              <label
                tabIndex={0}
                onClick={_e => {
                  if (formikProps.values[`exercise${index + 1}`] === 'GROWINGPUPPY') {
                    let nextElement = document.querySelector('.medical-allergies-group' + (index + 1))
                    nextElement?.addEventListener('animationend', () => {
                      window.setTimeout(() => {
                        if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                      }, 1000)
                    })
                  } else {
                    let nextElement = document.querySelector('.exercise-group' + (index + 1))
                    nextElement?.addEventListener('animationend', () => {
                      window.setTimeout(() => {
                        if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                      }, 1000)
                    })
                  }
                }}
                onChange={e => {
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_physique', {
                      page: 'sign_up_dog_details',
                      physique: e.target.value
                    })
                  }

                  formikProps.handleChange(e)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`physique${index + 1}`], 'PERFECT')
                  }
                }}
                className={`${
                  formikProps.values[`physique${index + 1}`] === 'PERFECT'
                    ? 'btn-primary-active rounded-button'
                    : 'btn-primary rounded-button'
                } dog-image-wrapper m-2 inline-flex w-1/3 cursor-pointer flex-col flex-wrap items-center self-center sm:w-32`}
              >
                <StaticImage
                  objectFit={'contain'}
                  className="dog-physique block h-20 w-full"
                  src="../../../../images/dog-perfect-2.svg"
                  alt="Perfect Sized Dog"
                />
                <Field id="physique2" type="radio" name={`physique${index + 1}`} value="PERFECT" className="hidden" />
                <span>Perfect</span>
              </label>
              <label
                tabIndex={0}
                onClick={_e => {
                  if (formikProps.values[`exercise${index + 1}`] === 'GROWINGPUPPY') {
                    let nextElement = document.querySelector('.medical-allergies-group' + (index + 1))
                    nextElement?.addEventListener('animationend', () => {
                      window.setTimeout(() => {
                        if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                      }, 1000)
                    })
                  } else {
                    let nextElement = document.querySelector('.exercise-group' + (index + 1))
                    nextElement?.addEventListener('animationend', () => {
                      window.setTimeout(() => {
                        if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                      }, 1000)
                    })
                  }
                }}
                onChange={e => {
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_physique', {
                      page: 'sign_up_dog_details',
                      physique: e.target.value
                    })
                  }

                  formikProps.handleChange(e)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`physique${index + 1}`], 'OVERWEIGHT')
                  }
                }}
                className={`${
                  formikProps.values[`physique${index + 1}`] === 'OVERWEIGHT'
                    ? 'btn-primary-active rounded-button'
                    : 'btn-primary rounded-button'
                } dog-image-wrapper m-2 inline-flex w-1/3 cursor-pointer flex-col flex-wrap items-center self-center sm:w-32`}
              >
                <StaticImage
                  objectFit={'contain'}
                  className="dog-physique block h-20 w-full"
                  src="../../../../images/dog-chunky-2.svg"
                  alt="Chunky Sized Dog"
                />
                <Field
                  id="physique3"
                  type="radio"
                  name={`physique${index + 1}`}
                  value="OVERWEIGHT"
                  className="hidden"
                />
                <span>Chunky</span>
              </label>
            </div>
            {formikProps.touched[`physique${index + 1}`] && formikProps.errors[`physique${index + 1}`] ? (
              <div className="text-xs text-red-500">{formikProps.errors[`physique${index + 1}`]}</div>
            ) : null}
          </div>
          {/* How Much Exercise */}
          <div
            role="group"
            aria-labelledby="exercise-group"
            className={`exercise-group${index + 1} text-center ${
              formikProps.values[`exercise${index + 1}`] === 'GROWINGPUPPY'
                ? 'hidden'
                : formikProps.values[`physique${index + 1}`] || formikProps.touched[`physique${index + 1}`]
                  ? `${
                      (formikProps.values[`exercise${index + 1}`] || formikProps.touched[`exercise${index + 1}`]) &&
                      `dog-details-line-7`
                    } animate__animated animate__fadeInUp w-full justify-center md:w-1/2 md:justify-self-start`
                  : 'hidden'
            }`}
          >
            <StaticImage width={75} src="../../../../images/dog-details/signup-dog-details-7.svg" alt="Question 7" />
            <h4 className="mb-0 bg-white pb-4 text-2xl md:text-3xl">
              How much exercise does {dogName ? dogName : 'your dog'} get per day?
            </h4>
            <span className="font-third block bg-white pb-4 text-4xl">
              The more active your pup... the more food they need!
            </span>
            <div className="flex justify-center">
              <label
                tabIndex={0}
                onClick={_e => {
                  let nextElement = document.querySelector('.medical-allergies-group' + (index + 1))
                  nextElement?.addEventListener('animationend', () => {
                    window.setTimeout(() => {
                      if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                    }, 1000)
                  })
                }}
                onChange={e => {
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_exercise', {
                      page: 'sign_up_dog_details',
                      exercise: e.target.value
                    })
                  }

                  formikProps.handleChange(e)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`exercise${index + 1}`], 'LOW')
                  }
                }}
                className={`${
                  formikProps.values[`exercise${index + 1}`] === 'LOW' ? 'btn-primary-active' : 'btn-primary'
                } btn-toggle-left inline-flex w-1/3 cursor-pointer flex-col flex-wrap justify-center self-stretch sm:w-32`}
              >
                <Field data-testid="LOW" type="radio" name={`exercise${index + 1}`} value="LOW" className="hidden" />
                {'0 - 1 Hour'}
              </label>
              <label
                tabIndex={0}
                onClick={_e => {
                  let nextElement = document.querySelector('.medical-allergies-group' + (index + 1))
                  nextElement?.addEventListener('animationend', () => {
                    window.setTimeout(() => {
                      if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                    }, 1000)
                  })
                }}
                onChange={e => {
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_exercise', {
                      page: 'sign_up_dog_details',
                      exercise: e.target.value
                    })
                  }

                  formikProps.handleChange(e)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`exercise${index + 1}`], 'MEDIUM')
                  }
                }}
                className={`${
                  formikProps.values[`exercise${index + 1}`] === 'MEDIUM' ? 'btn-primary-active' : 'btn-primary'
                } btn-toggle-center inline-flex w-1/3 cursor-pointer flex-col flex-wrap justify-center self-stretch sm:w-32`}
              >
                <Field
                  data-testid="MEDIUM"
                  type="radio"
                  name={`exercise${index + 1}`}
                  value="MEDIUM"
                  className="hidden"
                />
                {'1 - 2 Hours'}
              </label>
              <label
                tabIndex={0}
                onClick={_e => {
                  let nextElement = document.querySelector('.medical-allergies-group' + (index + 1))
                  nextElement?.addEventListener('animationend', () => {
                    window.setTimeout(() => {
                      if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                    }, 1000)
                  })
                }}
                onChange={e => {
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'selected_exercise', {
                      page: 'sign_up_dog_details',
                      exercise: e.target.value
                    })
                  }

                  formikProps.handleChange(e)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    formikProps.setFieldValue([`exercise${index + 1}`], 'HIGH')
                  }
                }}
                className={`${
                  formikProps.values[`exercise${index + 1}`] === 'HIGH' ? 'btn-primary-active' : 'btn-primary'
                } btn-toggle-right inline-flex w-1/3 cursor-pointer flex-col flex-wrap justify-center self-stretch sm:w-32`}
              >
                <Field type="radio" name={`exercise${index + 1}`} value="HIGH" className="hidden" />
                {'2+ Hours'}
              </label>
            </div>
            {formikProps.touched[`exercise${index + 1}`] && formikProps.errors[`exercise${index + 1}`] ? (
              <div className="text-xs text-red-500">{formikProps.errors[`exercise${index + 1}`]}</div>
            ) : null}
          </div>
          {/* Medical Conditions or Allergies */}
          <div
            style={{ zIndex: 1 }}
            role="group"
            aria-labelledby="medical-allergies-group"
            className={`medical-allergies-group${index + 1} ${
              (formikProps.values[`physique${index + 1}`] && formikProps.values[`exercise${index + 1}`]) ||
              formikProps.touched[`physique${index + 1}`] ||
              formikProps.touched[`exercise${index + 1}`]
                ? `${
                    formikProps.values[`medicalConditionOrAllergies${index + 1}`] &&
                    dogsCtx.checkIfLastQuestionisMedicalConditions() &&
                    ``
                  } animate__animated animate__fadeInUp relative overflow-visible`
                : 'hidden'
            } ${
              formikProps.values[`exercise${index + 1}`] === 'GROWINGPUPPY'
                ? 'w-full justify-center text-center md:w-1/2 md:justify-self-start'
                : 'w-full justify-center text-center md:w-1/2 md:justify-self-end'
            }`}
          >
            {formikProps.values[`exercise${index + 1}`] === 'GROWINGPUPPY' ? (
              <StaticImage width={75} src="../../../../images/dog-details/signup-dog-details-7.svg" alt="Question 7" />
            ) : (
              <StaticImage width={75} src="../../../../images/dog-details/signup-dog-details-8.svg" alt="Question 8" />
            )}
            <h4 className="bg-white pb-4 text-2xl md:text-3xl">
              Does {dogName ? dogName : 'your dog'} have any medical conditions or allergies?
            </h4>
            <span className="font-third block bg-white pb-4 text-4xl">
              With 13 recipes we have something for every dog
            </span>
            <label
              tabIndex={0}
              onChange={e => {
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'selected_medical_conditions_or_allergies', {
                    page: 'sign_up_dog_details',
                    medical_conditions_or_allergies: e.target.value
                  })
                }

                formikProps.handleChange(e)
              }}
              onClick={_e => {
                window.setTimeout(() => {
                  let nextElement = document.querySelector('.calculate-plan')
                  if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                }, 3000)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  formikProps.setFieldValue([`medicalConditionOrAllergies${index + 1}`], 'YES')
                }
              }}
              className={`${
                formikProps.values[`medicalConditionOrAllergies${index + 1}`] === 'YES'
                  ? 'btn-primary-active'
                  : 'btn-primary'
              } btn-toggle-left inline-flex w-1/3 cursor-pointer flex-col flex-wrap self-center sm:w-24`}
            >
              <Field type="radio" name={`medicalConditionOrAllergies${index + 1}`} value="YES" className="hidden" />
              {'Yes'}
            </label>
            <label
              tabIndex={0}
              onChange={e => {
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'selected_medical_conditions_or_allergies', {
                    page: 'sign_up_dog_details',
                    medical_conditions_or_allergies: e.target.value
                  })
                }

                formikProps.handleChange(e)
              }}
              onClick={_e => {
                window.setTimeout(() => {
                  let nextElement = document.querySelector('.calculate-plan')
                  if (scrollEnabled) nextElement?.scrollIntoView({ block: 'center' })
                }, 1000)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  formikProps.setFieldValue([`medicalConditionOrAllergies${index + 1}`], 'NO')
                }
              }}
              className={`${
                formikProps.values[`medicalConditionOrAllergies${index + 1}`] === 'NO'
                  ? 'btn-primary-active'
                  : 'btn-primary'
              } btn-toggle-right inline-flex w-1/3 cursor-pointer flex-col flex-wrap self-center sm:w-24`}
            >
              <Field
                data-testid="medicalConditionOrAllergies1"
                type="radio"
                name={`medicalConditionOrAllergies${index + 1}`}
                value="NO"
                className="hidden"
              />
              {'No'}
            </label>
            {
              <p
                className={
                  formikProps.values[`medicalConditionOrAllergies${index + 1}`] === 'YES' ? 'mb-4 mt-4 block' : 'hidden'
                }
              >
                When choosing recipes, you can view all the ingredients, or for further advice on what to feed we offer
                a free call with our vet or nutritionist. Get in touch to set it up, or continue below.
              </p>
            }
            {formikProps.touched[`medicalConditionOrAllergies${index + 1}`] &&
            formikProps.errors[`medicalConditionOrAllergies${index + 1}`] ? (
              <div className="text-xs text-red-500">
                {formikProps.errors[`medicalConditionOrAllergies${index + 1}`]}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DogAccordionItem
