import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { navigate } from '@reach/router'
import { useLocation } from '@reach/router'
import { extractPublicId } from 'cloudinary-build-url'
import TrustPilotMicroCombo from 'components/ui-builder/trustpilot/micro-combo'
import TrustPilotMini from 'components/ui-builder/trustpilot/mini'
import FeedbackForm from 'components/ui/feedback-form/FeedbackForm'
import RecipeOrTreatModalAlter from 'components/ui/modal/RecipeOrTreatModalAlter'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import TrustBoxAlternate from 'components/ui/trustpilot/TrustBoxAlternate'
import CloudinaryImage from 'components/util/CloudinaryImage'
import { Field, Formik } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import { parse, stringify } from 'query-string'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updateKey } from 'state/actions/keyAction'
import { addRecipes, prefillSignupRecipes, removeRecipes, updateChangedStatus } from 'state/actions/planAction'
import { getProducts } from 'state/actions/productsAction'
import { CouponType, CouponsType } from 'state/types/dogType'
import { PlanType, RecipeAddonType, RecipeType } from 'state/types/planType'
import { RecipesSchema } from 'utils/yup-validation-schema'
import BritishFlag from '../../../images/BritishFlag.svg'
import BulletPointImage from '../../../images/BulletPoint.svg'

const Recipes = () => {
  const {
    plan,
    noOfPacks,
    packSize,
    changed,
    loading,
    recipesSelected
  }: {
    plan: PlanType
    noOfPacks: number | undefined
    packSize: number | undefined
    changed: boolean
    loading: boolean
    recipesSelected: RecipeType[]
  } = useSelector((state: RootState) => state.plan)
  const {
    lastValidatedCoupon,
    lastValidatedCoupons
  }: {
    lastValidatedCoupon: CouponType
    lastValidatedCoupons: CouponsType[]
  } = useSelector((state: RootState) => state.dog)
  const products = useSelector((state: RootState) => state.products)
  // Get recipe list from product store, else use old code of plan.recipes
  // TODO: Can be updated once product store in place
  const { recipeList }: { recipeList: RecipeAddonType[] } = products || { recipeList: plan?.recipes }
  const [recipeCount, setRecipeCount] = useState(0)
  const [recipes, setRecipes] = useState<Array<{ id: string; quantity: number }>>([])
  const [modalShow, setModalShow] = useState(false)
  const [modalActiveRecipe, setModalActiveRecipe] = useState({})
  const { key } = useSelector((state: RootState) => state.key)
  const location = useLocation()

  const dispatch = useDispatch()
  const treatPageBeforeCheckout = useFeatureIsOn('treat-page-before-checkout')

  useEffect(() => {
    // if (!products)
    dispatch(getProducts())
  }, [])

  const backClick = () => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'clicked_back', {
        page: 'sign_up_recipe'
      })
    }

    dispatch(updateKey('plan'))
    navigate(`/signup/plan/`)
  }

  const handleAddRecipe = (recipeId: string) => {
    if (typeof ttq !== 'undefined') {
      ttq.track('AddToWishlist')
    }

    let recipesFormData: any = []
    if (recipes) {
      const index = recipes.findIndex(recipe => recipe.id === recipeId)
      if (index !== -1) {
        const recipesArr = [...recipes]
        recipesArr[index].quantity = recipesArr[index].quantity + 1
        recipesFormData = [...recipesArr]
        setRecipes(recipesFormData)
      } else {
        recipesFormData = [...recipes, { id: recipeId, quantity: 1 }]
        setRecipes(recipesFormData)
      }
    } else {
      recipesFormData = [{ id: recipeId, quantity: 1 }]
      setRecipes(recipesFormData)
    }

    if (typeof window.gtag !== 'undefined' && plan?.recipes?.length > 0) {
      const index = recipeList?.findIndex(recipe => recipe.itemId === recipeId)
      const recipesIndex = recipes?.findIndex(recipe => recipe.id === recipeId)
      window.gtag('event', 'select_item', {
        item_list_id: 'recipes',
        item_list_name: 'Recipes',
        items: [
          {
            item_id: recipeList[index].itemId,
            item_name: recipeList[index].name,
            coupon: lastValidatedCoupon.code,
            item_list_id: recipeList[index].analytics?.item_list_id,
            item_list_name: recipeList[index].analytics?.item_list_name
          }
        ]
      })

      window.gtag('event', 'add_to_cart', {
        currency: 'GBP',
        items: [
          {
            item_id: recipeList[index].itemId,
            item_name: recipeList[index].name,
            coupon: lastValidatedCoupon.code,
            quantity: recipesIndex === -1 ? 1 : recipes[recipesIndex].quantity,
            item_list_id: recipeList[index].analytics?.item_list_id,
            item_list_name: recipeList[index].analytics?.item_list_name
          }
        ]
      })

      window.gtag('event', 'added_recipe', {
        page: 'sign_up_recipe',
        recipe_id: recipeList[index].itemId,
        recipe_name: recipeList[index].name,
        quantity: recipesIndex === -1 ? 1 : recipes[recipesIndex].quantity
      })
    }
    setRecipeCount(curr => curr + 1)
    dispatch(addRecipes(recipesFormData, false))
  }

  const handleRemoveRecipe = (recipeId: string) => {
    let recipesFormData: any = []
    if (recipes) {
      const index = recipes.findIndex(recipe => recipe.id === recipeId)
      const plansRecipesIndex = recipeList?.findIndex(recipe => recipe.itemId === recipeId)

      if (index !== -1) {
        if (typeof window.gtag !== 'undefined' && plan?.recipes?.length > 0) {
          window.gtag('event', 'remove_from_cart', {
            currency: 'GBP',
            items: [
              {
                item_id: recipeList[plansRecipesIndex].itemId,
                item_name: recipeList[plansRecipesIndex].name,
                coupon: lastValidatedCoupon.code,
                quantity: recipes[index].quantity - 1,
                item_list_id: recipeList[plansRecipesIndex].analytics?.item_list_id,
                item_list_name: recipeList[plansRecipesIndex].analytics?.item_list_name
              }
            ]
          })

          window.gtag('event', 'removed_recipe', {
            page: 'sign_up_recipe',
            recipe_id: recipeList[plansRecipesIndex].itemId,
            recipe_name: recipeList[plansRecipesIndex].name,
            quantity: recipes[index].quantity - 1
          })
        }

        if (recipes[index].quantity > 1) {
          const recipesArr = [...recipes]
          recipesArr[index].quantity = recipesArr[index].quantity - 1
          recipesFormData = [...recipesArr]
          setRecipes(recipesFormData)
        } else {
          recipesFormData = recipes.filter(recipe => recipe.id !== recipeId)
          setRecipes(recipesFormData)
        }
        setRecipeCount(curr => curr - 1)
      }
    }
    dispatch(addRecipes(recipesFormData, false))
  }

  const getRecipeQuantity = (recipeId: string) => {
    if (recipes) {
      const index = recipes.findIndex(recipe => recipe.id === recipeId)
      if (index !== -1) {
        return recipes[index].quantity
      }
    }
    return 0
  }

  useEffect(() => {
    if (key !== 'recipes') {
      navigate(`/signup/${key}/${location.search && '?' + stringify(parse(location.search))}`)
    }
  }, [])

  useEffect(() => {
    // Check if the recipe list contains the old product Ids
    const oldRecipeIds = recipesSelected.some(recSelected =>
      recipeList.some(recList => recList.itemId === recSelected.id)
    )
    // If old product Ids, empty the  recipe selected list
    if (!oldRecipeIds) {
      dispatch(removeRecipes())
    }
    if (plan?.recipes?.length > 0 && recipesSelected?.length === 0) {
      let totalRecipes = 0
      const recipesSelectedLocal: any = []
      plan.recipes.map(recipe => {
        if (recipe.quantity > 0) {
          recipesSelectedLocal.push({ id: recipe.itemId, quantity: recipe.quantity })
          totalRecipes += recipe.quantity
        }
      })
      if (recipesSelectedLocal?.length > 0) {
        setRecipes(recipesSelectedLocal)
        dispatch(prefillSignupRecipes(recipesSelectedLocal))
        setRecipeCount(totalRecipes)
      }
    }
  }, [])

  useEffect(() => {
    if (recipesSelected) {
      let totalRecipes = 0
      setRecipes(recipesSelected)
      recipesSelected.forEach(recipe => {
        totalRecipes += recipe.quantity
      })
      setRecipeCount(totalRecipes)
    }
  }, [recipesSelected])

  useEffect(() => {
    if (changed && recipesSelected?.length) {
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'chosen_recipes', {
          page: 'sign_up_recipe'
        })
      }

      // Check for selected recipe range and send an event
      let range = ''
      const count = {
        standard: 0,
        health: 0,
        total: recipeCount
      }
      recipesSelected.forEach(s => {
        const { range } = recipeList?.find(r => r.itemId === s.id)
        if (range === 'standard') count.standard += s.quantity
        if (range === 'health') count.health += s.quantity
      })
      if (count.standard === count.total) {
        range = 'standard'
      } else if (count.health === count.total) {
        range = 'health'
      } else {
        range = 'mixed'
      }
      console.log({ count, range })
      if (typeof window.gtag !== 'undefined' && range) {
        window.gtag('event', 'chosen_recipes_range', {
          page: 'sign_up_recipe',
          range
        })
      }
      if (treatPageBeforeCheckout) {
        dispatch(updateKey('treats'))
        navigate(`/signup/treats/${location.search && '?' + stringify(parse(location.search))}`)
      } else {
        dispatch(updateKey('checkout'))
        navigate(`/signup/checkout/${location.search && '?' + stringify(parse(location.search))}`)
      }
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  useEffect(() => {
    if (typeof window.gtag !== 'undefined' && plan?.recipes?.length > 0) {
      window.gtag('event', 'view_item_list', {
        page: 'sign_up_recipe',
        item_list_id: 'recipes',
        item_list_name: 'Recipes',
        items: plan?.recipes?.map(r => {
          return {
            item_id: r.id,
            item_name: r.name,
            coupon: lastValidatedCoupon.code,
            item_list_id: r.analytics?.item_list_id,
            item_list_name: r.analytics?.item_list_name
          }
        })
      })
    }
  }, [])

  // const AddRemoveRecipeButton = ({ recipe = [], isMedium = false, outOfStock = false }: any) => {
  //   return (
  //     <div
  //       className={
  //         isMedium
  //           ? `hidden items-center justify-center sm:flex sm:pt-0 md:hidden`
  //           : `flex items-center justify-center pt-4 sm:hidden md:flex`
  //       }
  //     >
  //       {!outOfStock && getRecipeQuantity(recipe.id) > 0 && (
  //         <button type="button" className={'btn-cta mr-4 h-10 w-10'} onClick={() => handleRemoveRecipe(recipe.id)}>
  //           -
  //         </button>
  //       )}
  //       {!outOfStock && getRecipeQuantity(recipe.id) > 0 && (
  //         <Field
  //           name="recipe"
  //           type="text"
  //           placeholder="0"
  //           className="w-1/3 rounded-md border border-gray-300 py-3 px-4 text-center shadow-sm focus:border-primary focus:ring-primary"
  //           value={getRecipeQuantity(recipe.id)}
  //           inputMode="none"
  //           onFocus={(e: any) => e.target.blur()}
  //         />
  //       )}
  //       {!outOfStock && getRecipeQuantity(recipe.id) > 0 && (
  //         <button
  //           data-testid="recipesPlus"
  //           type="button"
  //           className={`${noOfPacks && recipeCount < noOfPacks ? 'visible' : 'invisible'} btn-cta ml-4 h-10 w-10`}
  //           onClick={() => handleAddRecipe(recipe.id)}
  //         >
  //           +
  //         </button>
  //       )}
  //       {!outOfStock && getRecipeQuantity(recipe.id) === 0 && (
  //         <button
  //           data-testid="addRecipe"
  //           type="button"
  //           className={`${
  //             noOfPacks && recipeCount < noOfPacks ? 'visible' : 'invisible'
  //           } btn-cta !px-8 !text-base !text-white`}
  //           onClick={() => handleAddRecipe(recipe.id)}
  //         >
  //           Add
  //         </button>
  //       )}

  //       {outOfStock && <span className="btn-cta cursor-not-allowed !px-8 !text-base !text-white">Out of stock</span>}
  //     </div>
  //   )
  // }

  const getRecipeHighlight = (highlight: any) => {
    // Create highlight from two highlights
    if (highlight.length > 1) {
      const highlightTwo = highlight[1].replace('100% British', '')
      return highlight[0] + ' and ' + highlightTwo
    }
    return highlight
  }

  const getRecipePrice = (recipe: string) => {
    const price = plan?.recipes?.filter((r: any) => r.itemId === recipe)
    if (price?.length) return price[0].price / 100
  }

  return (
    <>
      <div className="relative">
        <div className="header-wave-new h-[270px]" />
        <Formik
          initialValues={{ recipes: [] }}
          validationSchema={RecipesSchema}
          onSubmit={() => {
            if (typeof window.gtag !== 'undefined') {
              window.gtag('event', 'clicked_next', {
                page: 'sign_up_recipe'
              })
            }

            dispatch(addRecipes(recipes, true))
          }}
          validateOnMount
        >
          {props => {
            return (
              <div className="relative">
                <div className="pt-6 mb-4">
                  <h1
                    data-testid="chooseYourRecipes"
                    className="p-4 text-center text-2xl tracking-tight sm:text-3xl sm:text-5xl"
                  >
                    What's on the menu?
                  </h1>
                  <p className="mb-4 text-center">
                    All our recipes are nutritionally balanced by our expert canine nutritionist, Bianca.
                  </p>
                  <div className="w-full text-center mb-6">
                    <p>
                      You have{' '}
                      <span className="rounded bg-[#F58C82] p-2 text-lg font-bold text-white">{recipeCount}</span> out
                      of <span className="rounded bg-[#F58C82] p-2 text-lg font-bold text-white">{noOfPacks}</span>{' '}
                      packs selected
                    </p>
                  </div>
                </div>
                <ul className="mx-auto grid w-full grid-cols-1 gap-y-6 gap-x-8 p-4 pt-0 xs:grid-cols-2 md:max-w-[80%] md:grid-cols-2 lg:grid-cols-4">
                  {recipeList &&
                    recipeList
                      .filter(recipe => recipe.range === 'standard')
                      .map((recipe: { itemId: string; name: string; imageUrlNormal: string }, _key: number) => {
                        const outOfStock = recipe.status === 'OUTOFSTOCK'
                        const selectedRecipe = recipes?.find(r => r.id === recipe.itemId)
                        return (
                          <li
                            className={`relative overflow-hidden rounded-3xl bg-[#F8F2EC] ${
                              outOfStock && 'grayscale filter'
                            }
                            ${selectedRecipe && 'shadow-[0_0_0_4px_rgba(0,102,133,1)]'}`}
                            key={recipe.itemId}
                          >
                            <div className={`flex h-full flex-col justify-between text-center`}>
                              <div className="flex h-full flex-col">
                                <div className="block grid-cols-3 md:grid md:grid-cols-1">
                                  <div className="aspect-h-1 relative mx-auto w-full overflow-hidden md:rounded-none md:h-[180px] xl:h-[220px]">
                                    {recipe?.images?.noBackground && (
                                      <CloudinaryImage
                                        publicId={extractPublicId(recipe?.images?.noBackground)}
                                        alt={recipe.name}
                                        steps={[550]}
                                        className={`max-h-[150px] w-full scale-150 transform object-contain xs:min-h-[150px] xs:object-cover md:max-h-full md:scale-100 block bg-[#F6E9DB]`}
                                      />
                                    )}
                                  </div>
                                  {recipe.bestseller && (
                                    <span
                                      style={{ lineHeight: '1rem', right: '0.5rem' }}
                                      className="absolute top-[15%] inline-flex h-11 w-11 -rotate-12 items-center justify-center rounded-full bg-healthBG text-center text-[0.65rem] text-healthText drop-shadow md:h-20 md:w-20 md:text-[1rem] xl:inline-flex"
                                    >
                                      Popular
                                    </span>
                                  )}
                                  {outOfStock && (
                                    <span
                                      style={{ lineHeight: '1rem', right: '0.5rem' }}
                                      className="absolute top-[15%] inline-flex h-11 w-11 -rotate-12 items-center justify-center rounded-full bg-[#C6DABB] text-center text-[0.65rem] drop-shadow md:h-20 md:w-20 md:text-[1rem] xl:inline-flex"
                                    >
                                      Out of stock
                                    </span>
                                  )}
                                  <div className="col-span-2 flex h-auto flex-grow flex-col p-4">
                                    <h4 className="mb-0 md:mb-2 text-center md:text-xl">{recipe.name}</h4>

                                    <p className="mt-4 flex h-full flex-grow flex-col text-center text-xs text-black md:text-sm">
                                      {recipe.shortDescription}{' '}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-col md:flex-row mx-auto justify-center items-center md:items-start w-full md:mb-4">
                                {recipe?.bullets && (
                                  <div className="flex flex-row md:flex-col mx-auto justify-center w-full items-center md:w-1/2">
                                    <img width={20} src={BulletPointImage} alt={'Logo'} />

                                    <span className="ml-2 mt-1 inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                      {recipe.bullets[0]}
                                    </span>
                                  </div>
                                )}
                                {recipe?.highlights && (
                                  <div className="my-4 md:my-0 flex flex-row md:flex-col mx-auto justify-center items-center w-full md:w-1/2">
                                    <img width={20} src={BritishFlag} alt={'British Flag'} />
                                    <span className="ml-2 inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                      {getRecipeHighlight(recipe.highlights)}
                                    </span>
                                  </div>
                                )}
                              </div>

                              <div className="flex items-center justify-center">
                                {getRecipeQuantity(recipe.itemId) > 0 && (
                                  <button
                                    data-testid="btnAddMinus"
                                    type="button"
                                    className={`btn-cta mr-4 flex h-10 h-[30px] w-10 w-[30px] items-center !px-0 !py-0 !text-4xl disabled:bg-[#D1D5DA]`}
                                    onClick={() => handleRemoveRecipe(recipe.itemId)}
                                  >
                                    <span className="mb-[6px]">-</span>
                                  </button>
                                )}
                                {getRecipeQuantity(recipe.itemId) > 0 && (
                                  <Field
                                    name="recipe"
                                    type="text"
                                    placeholder="0"
                                    className="w-1/3 rounded-3xl border border-gray-300 py-3 px-4 text-center shadow-sm focus:border-primary focus:ring-primary"
                                    value={getRecipeQuantity(recipe.itemId)}
                                    inputMode="none"
                                    onFocus={(e: any) => e.target.blur()}
                                  />
                                )}
                                {getRecipeQuantity(recipe.itemId) > 0 && (
                                  <button
                                    data-testid="btnAddPlus"
                                    type="button"
                                    className={`btn-cta ml-4 flex h-10 h-[30px] w-10 w-[30px] items-center !px-0 !py-0 !text-4xl disabled:cursor-not-allowed disabled:bg-[#D1D5DA] disabled:grayscale disabled:filter`}
                                    onClick={() => handleAddRecipe(recipe.itemId)}
                                    disabled={noOfPacks && recipeCount >= noOfPacks}
                                  >
                                    +
                                  </button>
                                )}
                                {getRecipeQuantity(recipe.itemId) === 0 && (
                                  <button
                                    data-testid="addRecipe"
                                    type="button"
                                    disabled={outOfStock || (noOfPacks && recipeCount >= noOfPacks)}
                                    className={`${
                                      outOfStock && 'cursor-not-allowed'
                                    } btn-cta !px-8 !text-base !text-white disabled:cursor-not-allowed disabled:bg-[#D1D5DA]`}
                                    onClick={() => handleAddRecipe(recipe.itemId)}
                                  >
                                    {outOfStock ? 'Out of stock' : 'Add'}
                                  </button>
                                )}
                              </div>
                              <div className="my-2 text-center">
                                <button
                                  className="mb-2.5 text-xs text-black underline sm:text-sm"
                                  onClick={() => {
                                    setModalShow(true)
                                    setModalActiveRecipe(recipe)

                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'view_item', {
                                        currency: 'GBP',
                                        items: [
                                          {
                                            item_id: recipe.itemId,
                                            item_name: recipe.name,
                                            coupon: lastValidatedCoupon.code,
                                            item_list_id: recipe.analytics?.item_list_id,
                                            item_list_name: recipe.analytics?.item_list_name
                                          }
                                        ]
                                      })
                                    }
                                  }}
                                >
                                  Nutritional Information
                                </button>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                  {/* <li className="hidden h-full flex-col items-center justify-center rounded-3xl bg-[#D7EAD2] p-4 text-center xs:flex">
                    <h3 className="text-2xl">
                      Discover our <span className="text-3xl">Health+</span> range below
                    </h3>
                  </li> */}
                </ul>
                <ul className="mx-auto grid w-full grid-cols-1 gap-y-6 gap-x-8 p-4 pt-0 xs:grid-cols-2 md:max-w-[80%] md:grid-cols-2 lg:grid-cols-4">
                  {!recipeList &&
                    plan &&
                    plan.recipes &&
                    plan.recipes
                      .filter(recipe => recipe.range === 'standard')
                      .map((recipe: { id: string; name: string; imageUrlNormal: string }, _key: number) => {
                        // console.log(recipe, 'recipe!')
                        const outOfStock = recipe.status === 'OUTOFSTOCK'
                        const selectedRecipe = recipes?.find(r => r.id === recipe.id)
                        return (
                          <li
                            className={`relative overflow-hidden rounded-3xl bg-[#F8F2EC] ${
                              outOfStock && 'grayscale filter'
                            }
                            ${selectedRecipe && 'shadow-[0_0_0_4px_rgba(0,102,133,1)]'}`}
                            key={recipe.id}
                          >
                            <div className={`flex h-full flex-col justify-between text-center`}>
                              <div className="flex h-full flex-col">
                                <div className="block grid-cols-3 md:grid md:grid-cols-1">
                                  <div className="aspect-h-1 relative mx-auto w-full overflow-hidden md:rounded-none md:h-[180px] xl:h-[220px]">
                                    {recipe?.images?.noBackground && (
                                      <CloudinaryImage
                                        publicId={extractPublicId(recipe?.images?.noBackground)}
                                        alt={recipe.name}
                                        steps={[550]}
                                        className={`max-h-[150px] w-full scale-150 transform object-contain xs:min-h-[150px] xs:object-cover md:max-h-full md:scale-100 block bg-[#F6E9DB]`}
                                      />
                                    )}
                                  </div>
                                  {recipe.bestseller && (
                                    <span
                                      style={{ lineHeight: '1rem', right: '0.5rem' }}
                                      className="absolute top-[15%] inline-flex h-11 w-11 -rotate-12 items-center justify-center rounded-full bg-healthBG text-center text-[0.65rem] text-healthText drop-shadow md:h-20 md:w-20 md:text-[1rem] xl:inline-flex"
                                    >
                                      Popular
                                    </span>
                                  )}
                                  {outOfStock && (
                                    <span
                                      style={{ lineHeight: '1rem', right: '0.5rem' }}
                                      className="absolute top-[15%] inline-flex h-11 w-11 -rotate-12 items-center justify-center rounded-full bg-[#C6DABB] text-center text-[0.65rem] drop-shadow md:h-20 md:w-20 md:text-[1rem] xl:inline-flex"
                                    >
                                      Out of stock
                                    </span>
                                  )}
                                  <div className="col-span-2 flex h-auto flex-grow flex-col p-4">
                                    <h4 className="mb-0 md:mb-2 text-center md:text-xl">{recipe.name}</h4>

                                    <p className="mt-4 flex h-full flex-grow flex-col text-center text-xs text-black md:text-sm">
                                      {recipe.shortDescription}{' '}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-col md:flex-row mx-auto justify-center items-center md:items-start w-full md:mb-4">
                                {recipe?.bullets && (
                                  <div className="flex flex-row md:flex-col mx-auto justify-center w-full items-center md:w-1/2">
                                    <img width={20} src={BulletPointImage} alt={'Logo'} />

                                    <span className="ml-2 mt-1 inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                      {recipe.bullets[0]}
                                    </span>
                                  </div>
                                )}
                                {recipe?.highlights && (
                                  <div className="my-4 md:my-0 flex flex-row md:flex-col mx-auto justify-center items-center w-full md:w-1/2">
                                    <img width={20} src={BritishFlag} alt={'British Flag'} />
                                    <span className="ml-2 inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                      {getRecipeHighlight(recipe.highlights)}
                                    </span>
                                  </div>
                                )}
                              </div>

                              <div className="flex items-center justify-center">
                                {getRecipeQuantity(recipe.id) > 0 && (
                                  <button
                                    data-testid="btnAddMinus"
                                    type="button"
                                    className={`btn-cta mr-4 flex h-10 h-[30px] w-10 w-[30px] items-center !px-0 !py-0 !text-4xl disabled:bg-[#D1D5DA]`}
                                    onClick={() => handleRemoveRecipe(recipe.id)}
                                  >
                                    <span className="mb-[6px]">-</span>
                                  </button>
                                )}
                                {getRecipeQuantity(recipe.id) > 0 && (
                                  <Field
                                    name="recipe"
                                    type="text"
                                    placeholder="0"
                                    className="w-1/3 rounded-3xl border border-gray-300 py-3 px-4 text-center shadow-sm focus:border-primary focus:ring-primary"
                                    value={getRecipeQuantity(recipe.id)}
                                    inputMode="none"
                                    onFocus={(e: any) => e.target.blur()}
                                  />
                                )}
                                {getRecipeQuantity(recipe.id) > 0 && (
                                  <button
                                    data-testid="btnAddPlus"
                                    type="button"
                                    className={`btn-cta ml-4 flex h-10 h-[30px] w-10 w-[30px] items-center !px-0 !py-0 !text-4xl disabled:cursor-not-allowed disabled:bg-[#D1D5DA] disabled:grayscale disabled:filter`}
                                    onClick={() => handleAddRecipe(recipe.id)}
                                    disabled={noOfPacks && recipeCount >= noOfPacks}
                                  >
                                    +
                                  </button>
                                )}
                                {getRecipeQuantity(recipe.id) === 0 && (
                                  <button
                                    data-testid="addRecipe"
                                    type="button"
                                    disabled={outOfStock || (noOfPacks && recipeCount >= noOfPacks)}
                                    className={`${
                                      outOfStock && 'cursor-not-allowed'
                                    } btn-cta !px-8 !text-base !text-white disabled:cursor-not-allowed disabled:bg-[#D1D5DA]`}
                                    onClick={() => handleAddRecipe(recipe.id)}
                                  >
                                    {outOfStock ? 'Out of stock' : 'Add'}
                                  </button>
                                )}
                              </div>
                              <div className="my-2 text-center">
                                <button
                                  className="mb-2.5 text-xs text-black underline sm:text-sm"
                                  onClick={() => {
                                    setModalShow(true)
                                    setModalActiveRecipe(recipe)

                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'view_item', {
                                        currency: 'GBP',
                                        items: [
                                          {
                                            item_id: recipe.id,
                                            item_name: recipe.name,
                                            coupon: lastValidatedCoupon.code,
                                            item_list_id: recipe.analytics?.item_list_id,
                                            item_list_name: recipe.analytics?.item_list_name
                                          }
                                        ]
                                      })
                                    }
                                  }}
                                >
                                  Nutritional Information
                                </button>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                  {/* <li className="hidden h-full flex-col items-center justify-center rounded-3xl bg-[#D7EAD2] p-4 text-center xs:flex">
                    <h3 className="text-2xl">
                      Discover our <span className="text-3xl">Health+</span> range below
                    </h3>
                  </li> */}
                </ul>

                <div className="grid justify-center p-4">
                  <h2 className="p-4 text-center text-3xl tracking-tight">Health+</h2>
                  <p className="mb-4 max-w-[586px] text-center">
                    Our Health+ range is the UK's only freshly cooked dog food range specially designed to support
                    common health conditions.
                  </p>
                </div>
                <ul className="mx-auto mb-16 grid w-full grid-cols-1 gap-y-8 gap-x-8 p-4 pt-0 xs:grid-cols-2 md:max-w-[80%] md:grid-cols-2 lg:grid-cols-4">
                  {recipeList &&
                    recipeList
                      .filter(recipe => recipe.range === 'health')
                      .map(
                        (
                          recipe: { itemId: string; name: string; imageUrlNormal: string; price: number },
                          _key: number
                        ) => {
                          const outOfStock = recipe.status === 'OUTOFSTOCK'
                          console.log('RECIPE STATUS: ', recipe.status)
                          const selectedRecipe = recipes?.find(r => r.id === recipe.itemId)
                          return (
                            <li
                              className={`relative rounded-3xl bg-[#D7EAD2] ${outOfStock && 'grayscale filter'} overflow-hidden ${selectedRecipe && 'shadow-[0_0_0_4px_rgba(0,102,133,1)]'}`}
                              key={recipe.itemId}
                            >
                              <div className="flex h-full flex-1 flex-col justify-between text-center ">
                                <div className="flex h-full flex-col">
                                  <div className="block grid-cols-3 md:grid md:grid-cols-1">
                                    <div className="aspect-h-1 relative mx-auto w-full overflow-hidden md:h-[180px] xl:h-[220px] md:rounded-t-3xl mt-0">
                                      {recipe?.images?.noBackground && (
                                        <CloudinaryImage
                                          publicId={extractPublicId(recipe?.images?.noBackground)}
                                          alt={recipe.name}
                                          steps={[550]}
                                          className={`max-h-[150px] w-full scale-150 transform object-contain xs:min-h-[120px] xs:object-cover md:max-h-full md:scale-100 block bg-[#C6DABB]`}
                                        />
                                      )}
                                    </div>
                                    {recipe.bestseller && (
                                      <span
                                        style={{ lineHeight: '1rem', right: '0.5rem' }}
                                        className="absolute top-[15%] inline-flex h-11 w-11 -rotate-12 items-center justify-center rounded-full bg-healthBG text-center text-[0.65rem] text-healthText drop-shadow md:h-20 md:w-20 md:text-[1rem] xl:inline-flex"
                                      >
                                        Popular
                                      </span>
                                    )}
                                    {outOfStock && (
                                      <span
                                        style={{ lineHeight: '1rem', right: '0.5rem' }}
                                        className="absolute top-[15%] inline-flex h-11 w-11 -rotate-12 items-center justify-center rounded-full bg-[#C6DABB] text-center text-[0.65rem] drop-shadow md:h-20 md:w-20 md:text-[1rem] xl:inline-flex"
                                      >
                                        Out of stock
                                      </span>
                                    )}

                                    <div className="col-span-2 flex h-auto flex-grow flex-col">
                                      {recipe.adultOnly && (
                                        <div className="flex w-full justify-center text-center font-serif text-sm">
                                          <span className="bg-[#D1D5DA] py-2 mb-1 md:mb-0 whitespace-normal break-words w-full">
                                            Unsuitable for puppies & pregnant/nursing dogs
                                          </span>
                                        </div>
                                      )}
                                      <div className="col-span-2 flex h-auto flex-grow flex-col p-4">
                                        <h4 className="mb-0 md:mb-2 text-center md:text-xl">{recipe.name}</h4>
                                        <p className="mt-4 flex h-full flex-grow flex-col text-center text-xs text-black md:text-sm">
                                          {recipe.shortDescription}{' '}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-col md:flex-row mx-auto justify-center items-center md:items-start w-full md:my-2">
                                  {recipe?.bullets && (
                                    <div className="flex flex-row md:flex-col mx-auto justify-center items-center w-full md:w-1/2">
                                      <img width={20} src={BulletPointImage} alt={'Logo'} />
                                      <span className="ml-2 mt-1 inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                        {recipe.bullets[0]}
                                      </span>
                                    </div>
                                  )}
                                  {recipe?.highlights && (
                                    <div className="my-4 md:my-0 flex flex-row md:flex-col mx-auto justify-center items-center w-full md:w-1/2">
                                      <img width={20} src={BritishFlag} alt={'British Flag'} />
                                      <span className="ml-2 inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                        {getRecipeHighlight(recipe.highlights)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                {getRecipePrice(recipe.itemId) > 0 && (
                                  <div className="mx-auto flex flex-col items-center justify-center text-center">
                                    <p className="text-base md:text-xl font-bold text-primary">
                                      +£{getRecipePrice(recipe.itemId)}
                                    </p>
                                  </div>
                                )}
                                <div className="flex items-center justify-center">
                                  {getRecipeQuantity(recipe.itemId) > 0 && (
                                    <button
                                      data-testid="btnAddMinus"
                                      type="button"
                                      className={
                                        'btn-cta mr-4 flex h-10 h-[30px] w-10 w-[30px] items-center !px-0 !py-0 !text-4xl disabled:bg-[#D1D5DA]'
                                      }
                                      onClick={() => handleRemoveRecipe(recipe.itemId)}
                                    >
                                      <span className="mb-[6px]">-</span>
                                    </button>
                                  )}
                                  {getRecipeQuantity(recipe.itemId) > 0 && (
                                    <Field
                                      name="recipe"
                                      type="text"
                                      placeholder="0"
                                      className="w-1/3 rounded-3xl border border-gray-300 py-3 px-4 text-center shadow-sm focus:border-primary focus:ring-primary"
                                      value={getRecipeQuantity(recipe.itemId)}
                                      inputMode="none"
                                      onFocus={(e: any) => e.target.blur()}
                                    />
                                  )}
                                  {getRecipeQuantity(recipe.itemId) > 0 && (
                                    <button
                                      data-testid="btnAddPlus"
                                      type="button"
                                      disabled={noOfPacks && recipeCount >= noOfPacks}
                                      className={`btn-cta ml-4 flex h-10 h-[30px] w-10 w-[30px] items-center !px-0 !py-0 !text-4xl disabled:cursor-not-allowed disabled:bg-[#D1D5DA] disabled:grayscale disabled:filter`}
                                      onClick={() => handleAddRecipe(recipe.itemId)}
                                    >
                                      +
                                    </button>
                                  )}
                                  {getRecipeQuantity(recipe.itemId) === 0 && (
                                    <button
                                      data-testid="addRecipeHealthPlus"
                                      type="button"
                                      disabled={outOfStock || (noOfPacks && recipeCount >= noOfPacks)}
                                      className={`${
                                        outOfStock && 'cursor-not-allowed'
                                      } btn-cta !px-8 !text-base !text-white disabled:cursor-not-allowed disabled:bg-[#D1D5DA]`}
                                      onClick={() => handleAddRecipe(recipe.itemId)}
                                    >
                                      {outOfStock ? 'Out of stock' : 'Add'}
                                    </button>
                                  )}
                                </div>
                                <div className="my-2 text-center">
                                  <button
                                    className="mb-2.5 text-xs text-black underline sm:text-sm"
                                    onClick={() => {
                                      setModalShow(true)
                                      setModalActiveRecipe(recipe)

                                      if (typeof window.gtag !== 'undefined') {
                                        window.gtag('event', 'view_item', {
                                          currency: 'GBP',
                                          items: [
                                            {
                                              item_id: recipe.itemId,
                                              item_name: recipe.name,
                                              coupon: lastValidatedCoupon.code,
                                              item_list_id: recipe.analytics?.item_list_id,
                                              item_list_name: recipe.analytics?.item_list_name
                                            }
                                          ]
                                        })
                                      }
                                    }}
                                  >
                                    Nutritional Information
                                  </button>
                                </div>
                              </div>
                            </li>
                          )
                        }
                      )}
                  {recipeList && (
                    <li className="relative flex h-full flex-col items-center justify-start rounded-3xl bg-[#F8F2EC] p-4 text-center">
                      <h3 className="mb-4">Bianca</h3>
                      <p className="mb-4">Our Nutrition guru</p>
                      <p className="text-sm">
                        Expert Canine Nutritionist (PGDip Nutrition & Canine Nutrition, MSFTR, CPN)
                      </p>
                      <p className="pt-5 text-sm">
                        "We formulated the Health+ range for dogs that need a little extra TLC. Diet makes a huge
                        difference to many health conditions and we're proud to be cooking with natures best ingredients
                        and superfoods to support them."
                      </p>
                      <div className="w-full md:relative md:bottom-[-3rem]">
                        <StaticImage src="../../../images/recipe-Bianca.png" alt="Bianca" />
                      </div>
                    </li>
                  )}
                </ul>
                <ul className="mx-auto mb-16 grid w-full grid-cols-1 gap-y-8 gap-x-8 p-4 pt-0 xs:grid-cols-2 md:max-w-[80%] md:grid-cols-2 lg:grid-cols-4">
                  {!recipeList &&
                    plan &&
                    plan.recipes &&
                    plan.recipes
                      .filter(recipe => recipe.range === 'health')
                      .map(
                        (recipe: { id: string; name: string; imageUrlNormal: string; price: number }, _key: number) => {
                          const outOfStock = recipe.status === 'OUTOFSTOCK'
                          console.log('RECIPE STATUS: ', recipe.status)
                          const selectedRecipe = recipes?.find(r => r.id === recipe.id)
                          return (
                            <li
                              className={`relative rounded-3xl bg-[#D7EAD2] ${outOfStock && 'grayscale filter'} overflow-hidden ${selectedRecipe && 'shadow-[0_0_0_4px_rgba(0,102,133,1)]'}`}
                              key={recipe.id}
                            >
                              <div className="flex h-full flex-1 flex-col justify-between text-center">
                                <div className="flex h-full flex-col">
                                  <div className="block grid-cols-3 md:grid md:grid-cols-1">
                                    <div className="aspect-h-1 relative mx-auto w-full overflow-hidden md:h-[180px] xl:h-[220px] md:rounded-t-3xl">
                                      {recipe?.images?.primary && (
                                        <CloudinaryImage
                                          publicId={extractPublicId(recipe?.images?.primary)}
                                          alt={recipe.name}
                                          steps={[550]}
                                          className={`block max-h-[120px] w-full rounded-t-3xl object-cover xs:min-h-[120px] md:max-h-full lg:hidden`}
                                        />
                                      )}
                                      {recipe?.images?.noBackground && (
                                        <CloudinaryImage
                                          publicId={extractPublicId(recipe?.images?.noBackground)}
                                          alt={recipe.name}
                                          steps={[550]}
                                          className={`hidden max-h-[150px] w-full scale-150 transform object-contain xs:min-h-[120px] xs:object-cover md:max-h-full md:scale-100 lg:block bg-[#C6DABB]`}
                                        />
                                      )}
                                    </div>
                                    {recipe.bestseller && (
                                      <span
                                        style={{ lineHeight: '1rem', right: '0.5rem' }}
                                        className="absolute top-[15%] inline-flex h-11 w-11 -rotate-12 items-center justify-center rounded-full bg-healthBG text-center text-[0.65rem] text-healthText drop-shadow md:h-20 md:w-20 md:text-[1rem] xl:inline-flex"
                                      >
                                        Popular
                                      </span>
                                    )}
                                    {outOfStock && (
                                      <span
                                        style={{ lineHeight: '1rem', right: '0.5rem' }}
                                        className="absolute top-[15%] inline-flex h-11 w-11 -rotate-12 items-center justify-center rounded-full bg-[#C6DABB] text-center text-[0.65rem] drop-shadow md:h-20 md:w-20 md:text-[1rem] xl:inline-flex"
                                      >
                                        Out of stock
                                      </span>
                                    )}
                                    <div className="col-span-2 flex h-auto flex-grow flex-col">
                                      {recipe.adultOnly && (
                                        <div className="flex w-full justify-center text-center font-serif text-sm">
                                          <span className="bg-[#D1D5DA] py-2 mb-1 md:mb-0 whitespace-normal break-words w-full">
                                            Unsuitable for puppies & pregnant/nursing dogs
                                          </span>
                                        </div>
                                      )}
                                      <div className="col-span-2 flex h-auto flex-grow flex-col p-4">
                                        <h4 className="mb-0 md:mb-2 text-center md:text-xl">{recipe.name}</h4>
                                        <p className="mt-4 flex h-full flex-grow flex-col text-center text-xs text-black md:text-sm">
                                          {recipe.shortDescription}{' '}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-col md:flex-row mx-auto justify-center items-center md:items-start w-full md:my-2">
                                  {recipe?.bullets && (
                                    <div className="flex flex-row md:flex-col mx-auto justify-center items-center w-full md:w-1/2">
                                      <img width={20} src={BulletPointImage} alt={'Logo'} />
                                      <span className="ml-2 mt-1 inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                        {recipe.bullets[0]}
                                      </span>
                                    </div>
                                  )}
                                  {recipe?.highlights && (
                                    <div className="my-4 md:my-0 flex flex-row md:flex-col mx-auto justify-center items-center w-full md:w-1/2">
                                      <img width={20} src={BritishFlag} alt={'British Flag'} />
                                      <span className="ml-2 inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                        {getRecipeHighlight(recipe.highlights)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                {getRecipePrice(recipe.itemId) > 0 && (
                                  <div className="mx-auto flex flex-col items-center justify-center text-center">
                                    <p className="text-base md:text-xl font-bold text-primary">
                                      +£{getRecipePrice(recipe.itemId)}
                                    </p>
                                  </div>
                                )}
                                <div className="flex items-center justify-center">
                                  {getRecipeQuantity(recipe.id) > 0 && (
                                    <button
                                      data-testid="btnAddMinus"
                                      type="button"
                                      className={
                                        'btn-cta mr-4 flex h-10 h-[30px] w-10 w-[30px] items-center !px-0 !py-0 !text-4xl disabled:bg-[#D1D5DA]'
                                      }
                                      onClick={() => handleRemoveRecipe(recipe.id)}
                                    >
                                      <span className="mb-[6px]">-</span>
                                    </button>
                                  )}
                                  {getRecipeQuantity(recipe.id) > 0 && (
                                    <Field
                                      name="recipe"
                                      type="text"
                                      placeholder="0"
                                      className="w-1/3 rounded-3xl border border-gray-300 py-3 px-4 text-center shadow-sm focus:border-primary focus:ring-primary"
                                      value={getRecipeQuantity(recipe.id)}
                                      inputMode="none"
                                      onFocus={(e: any) => e.target.blur()}
                                    />
                                  )}
                                  {getRecipeQuantity(recipe.id) > 0 && (
                                    <button
                                      data-testid="btnAddPlus"
                                      type="button"
                                      disabled={noOfPacks && recipeCount >= noOfPacks}
                                      className={`btn-cta ml-4 flex h-10 h-[30px] w-10 w-[30px] items-center !px-0 !py-0 !text-4xl disabled:cursor-not-allowed disabled:bg-[#D1D5DA] disabled:grayscale disabled:filter`}
                                      onClick={() => handleAddRecipe(recipe.id)}
                                    >
                                      +
                                    </button>
                                  )}
                                  {getRecipeQuantity(recipe.id) === 0 && (
                                    <button
                                      data-testid="addRecipeHealthPlus"
                                      type="button"
                                      disabled={outOfStock || (noOfPacks && recipeCount >= noOfPacks)}
                                      className={`${
                                        outOfStock && 'cursor-not-allowed'
                                      } btn-cta !px-8 !text-base !text-white disabled:cursor-not-allowed disabled:bg-[#D1D5DA]`}
                                      onClick={() => handleAddRecipe(recipe.id)}
                                    >
                                      {outOfStock ? 'Out of stock' : 'Add'}
                                    </button>
                                  )}
                                </div>
                                <div className="my-2 text-center">
                                  <button
                                    className="mb-2.5 text-xs text-black underline sm:text-sm"
                                    onClick={() => {
                                      setModalShow(true)
                                      setModalActiveRecipe(recipe)

                                      if (typeof window.gtag !== 'undefined') {
                                        window.gtag('event', 'view_item', {
                                          currency: 'GBP',
                                          items: [
                                            {
                                              item_id: recipe.id,
                                              item_name: recipe.name,
                                              coupon: lastValidatedCoupon.code,
                                              item_list_id: recipe.analytics?.item_list_id,
                                              item_list_name: recipe.analytics?.item_list_name
                                            }
                                          ]
                                        })
                                      }
                                    }}
                                  >
                                    Nutritional Information
                                  </button>
                                </div>
                              </div>
                            </li>
                          )
                        }
                      )}
                  {!recipeList && (
                    <li className="relative flex h-full flex-col items-center justify-start rounded-3xl bg-[#F8F2EC] p-4 text-center">
                      <h3 className="mb-4">Bianca</h3>
                      <p className="mb-4">Our Nutrition guru</p>
                      <p className="text-sm">
                        Expert Canine Nutritionist (PGDip Nutrition & Canine Nutrition, MSFTR, CPN)
                      </p>
                      <p className="pt-5 text-sm">
                        "We formulated the Health+ range for dogs that need a little extra TLC. Diet makes a huge
                        difference to many health conditions and we're proud to be cooking with natures best ingredients
                        and superfoods to support them."
                      </p>
                      <div className="w-full md:relative md:bottom-[-3rem]">
                        <StaticImage src="../../../images/recipe-Bianca.png" alt="Bianca" />
                      </div>
                    </li>
                  )}
                </ul>
                <div className="footer-wave-bottom-signup relative -mb-32 p-4 md:p-8">
                  <div className="mb-4 w-full text-center font-serif">
                    <div className="mx-auto mt-8 text-base !leading-[1.3] md:text-xl">
                      <p>Still got questions?</p>
                      <p>Get in touch with our team - they could talk dogs all day long</p>
                    </div>
                    <p className="mt-8 text-base md:text-xl">
                      Call us on{' '}
                      <a className="font-bold" href="tel:+441743384562">
                        01743 384 562
                      </a>{' '}
                      or email{' '}
                      <a className="font-bold" href="mailto:feedme@differentdog.com">
                        feedme@differentdog.com
                      </a>{' '}
                    </p>
                  </div>
                  <div className="mt-10 hidden sm:block">
                    <TrustPilotMicroCombo backgroundColor="transparent" />
                  </div>
                  <div className="mt-5 -mb-8 block sm:hidden">
                    <TrustPilotMini backgroundColor="transparent" />
                  </div>
                  <div className="mx-auto mb-16 w-full md:w-4/5">
                    <TrustBoxAlternate />
                  </div>
                </div>

                <StickyFooter className="z-index-2 fixed bottom-0 left-0 right-0 ml-auto max-h-20 bg-white shadow-[0_-5px_15px_rgba(0,0,0,0.25)]">
                  <div className="w-full">
                    <div className="relative top-8 z-10 w-full text-center text-primary md:-ml-12 xl:top-4">
                      <p>
                        You have{' '}
                        <span data-testid="packsSelected" className="text-lg text-[#F58C82]">
                          {recipeCount}
                        </span>{' '}
                        out of{' '}
                        <span data-testid="packNbr" className="text-lg text-[#F58C82]">
                          {noOfPacks}
                        </span>{' '}
                        packs selected
                      </p>
                    </div>
                    <div className="relative -top-16 flex w-full items-center justify-between">
                      <button
                        data-testid="recipesBack"
                        onClick={() => {
                          backClick()
                        }}
                        type="button"
                        className="rounded-full border border-primary bg-white px-4 py-2 shadow-md hover:underline focus:underline"
                      >
                        Back
                      </button>

                      <button
                        onClick={() => props.handleSubmit()}
                        data-testid="nextBtn"
                        disabled={noOfPacks ? recipeCount < noOfPacks || noOfPacks - recipeCount < 0 || loading : true}
                        type="button"
                        className={`${
                          (noOfPacks ? recipeCount < noOfPacks || noOfPacks - recipeCount < 0 || loading : true)
                            ? '!bg-[#80B2C2]'
                            : ''
                        } btn-cta scale-animation !px-8 !py-4 !text-xl !font-bold shadow-[0_-5px_15px_rgba(0,0,0,0.25)] sm:!px-16`}
                      >
                        {loading ? (
                          <div className="align-center flex justify-center">
                            <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                            <div>Saving</div>
                          </div>
                        ) : (
                          'Next'
                        )}
                      </button>
                    </div>
                  </div>
                </StickyFooter>
              </div>
            )
          }}
        </Formik>
      </div>
      <RecipeOrTreatModalAlter show={modalShow} setShow={setModalShow} activeRecipe={modalActiveRecipe} />
      {/* <FeedbackForm source={'signup'} /> */}
    </>
  )
}

export default Recipes
