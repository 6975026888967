import React, { useEffect, useRef } from 'react'

const TrustBox = () => {
  const ref = useRef(null)
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      ref={ref}
      className="trustpilot-widget mt-10"
      data-locale="en-GB"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id="5af423d4a3303b0001bf47c1"
      data-style-height="140px"
      data-style-width="100%"
      data-theme="light"
      data-stars="5"
      data-review-languages="en"
      data-font-family="Montserrat"
      data-text-color="#004355"
    >
      <a href="https://uk.trustpilot.com/review/differentdog.com" target="_blank" rel="noreferrer noopener">
        Trustpilot
      </a>
    </div>
  )
}
export default TrustBox
