import { navigate } from '@reach/router'
import { useLocation } from '@reach/router'
import TrustPilotMini from 'components/ui-builder/trustpilot/mini'
import FaqAccordion from 'components/ui/faq-accordion'
import FeedbackForm from 'components/ui/feedback-form/FeedbackForm'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import dayjs from 'dayjs'
import { HeadingClass, HeadingType } from 'enums'
import { Field, Formik } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import { parse, stringify } from 'query-string'
import React, { useEffect, useState, useRef } from 'react'
import { MdInfoOutline } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { RootState } from 'state'
import { updateKey } from 'state/actions/keyAction'
import { postPlanId, updateChangedStatus } from 'state/actions/planAction'
import { removeAddons, removeRecipes, updateTrialBox } from 'state/actions/planAction'
import { getProducts } from 'state/actions/productsAction'
import { CouponType, CouponsType, DogType, PackNewType, PackType } from 'state/types/dogType'
import { PlanDetailSchema } from 'utils/yup-validation-schema'
import { faqs } from './data'

interface PlanCtxState {
  pack: PackType | undefined
  packSize: number | undefined
  noOfPacks: number | undefined
  weeks: number | undefined
  prevPackSize: number | undefined
  prevNoOfPacks: number | undefined
  prevWeeks: number | undefined
  packType: string | undefined
  handleFixedOrCustomPlan: (plan: string) => void
  handleIsDefaultPlan: (isDefault: boolean) => void
  handlePackType: (packType: string) => void
  handleSetPackSize: (size: number) => void
  handleSetNoOfPacks: (size: number) => void
  handleSetWeeks: (noOfWeeks: number) => void
}

interface IDog {
  ageStage: string
  dob: string
  exercise: string
  name: string
  neutered: string
  physique: string
  weight: number
  gender: string
}

export const PlanCtx = React.createContext<PlanCtxState>({} as PlanCtxState)

const Plan = () => {
  const dispatch = useDispatch()
  const {
    pack,
    defaultPack,
    dogInfo,
    lastValidatedCoupon,
    lastValidatedCoupons,
    packNew
  }: {
    pack: PackType | undefined
    defaultPack: string
    dogInfo: DogType
    lastValidatedCoupon: CouponType
    lastValidatedCoupons: CouponsType[]
    packNew: PackNewType
  } = useSelector((state: RootState) => state.dog)
  const {
    noOfPacks: prevNoOfPacks,
    packSize: prevPackSize,
    weeks: prevWeeks,
    isDefaultPlan: prevIsDefaultPlan,
    packType: prevPackType
  } = useSelector((state: RootState) => state.plan)
  const { changed, loading, trialBoxEnabled } = useSelector((state: RootState) => state.plan)
  const products = useSelector((state: RootState) => state.products)
  // Get productListHash from product store
  // TODO: Can be updated once product store in place
  const { productListHash } = products || { productListHash: '' }
  // const { productListHash } = useSelector((state: RootState) => state.products)

  const [isDefaultPlan, _setIsDefaultPlan] = useState(true)
  const [isNewPlan, setIsNewPlan] = useState(false)
  const [packType, setPackType] = useState<string>('')
  const [packSize, setPackSize] = useState<number | undefined>()
  const [noOfPacks, setNoOfPacks] = useState<number | undefined>()
  const [weeks, setWeeks] = useState<number | undefined>()
  const [sortedDogInfo, setSortedDogInfo] = useState<Array<IDog>>([])
  const [fixedOrCustomPlan, _setFixedOrCustomPlan] = useState('FIXED')
  const { key } = useSelector((state: RootState) => state.key)
  const [_weeksForTrialCheckbox, setWeeksForTrialCheckbox] = useState('')
  const [onGoingWeeks, setOnGoingWeeks] = useState('')
  const [onGoingPackQuantity, setOnGoingPackQuantity] = useState('')
  const [onGoingPackSize, setOnGoingPackSize] = useState('')
  const [packTypeSelected, setPackTypeSelected] = useState('')
  const [trialBoxChecked, setTrialBoxChecked] = useState(trialBoxEnabled)
  const [faqList, setFaqList] = useState(faqs)
  const location = useLocation()

  var formikRef = useRef<any>()

  // TODO: refactor the packNew code once more plans available

  useEffect(() => {
    // Populates the product store
    // if (!products)
    dispatch(getProducts())
  }, [])

  useEffect(() => {
    // Handle if we use new plan data or legacy
    if (packNew) {
      setIsNewPlan(true)
      // shift plan to array 1 if no full plan option
      if (packNew?.plans?.length === 1 && packNew.plans[0].size === 'partial') {
        packNew.plans.unshift({})
        setPackType(packNew.default)
      }
    }
  }, [packNew])

  useEffect(() => {
    // update this when more plans available or when design updates
    if (packType === 'fullTrial' || packType === 'full') {
      setWeeksForTrialCheckbox(packNew?.plans[0]?.trial?.weeks)
      setOnGoingPackSize(packNew?.plans[0]?.main?.packSize)
      setOnGoingWeeks(packNew?.plans[0]?.main?.weeks)
      setOnGoingPackQuantity(packNew?.plans[0]?.main?.numberOfPacks)
    }
    if (packType === 'partialTrial' || packType === 'partial') {
      setWeeksForTrialCheckbox(packNew?.plans[1]?.trial?.weeks)
      setOnGoingPackSize(packNew?.plans[1]?.main?.packSize)
      setOnGoingWeeks(packNew?.plans[1]?.main?.weeks)
      setOnGoingPackQuantity(packNew?.plans[1]?.main?.numberOfPacks)
    }
    if (packType === 'FULL_TRIAL' || packType === 'FULL') {
      setWeeksForTrialCheckbox(pack?.fullTrial?.weeks)
      setOnGoingPackSize(pack?.full?.packSize)
      setOnGoingWeeks(pack?.full?.weeks)
      setOnGoingPackQuantity(pack?.full?.numberOfPacks)
    }

    if (packType === 'PARTIAL_TRIAL' || packType === 'PARTIAL') {
      setWeeksForTrialCheckbox(pack?.partialTrial?.weeks)
      setOnGoingPackSize(pack?.partial?.packSize)
      setOnGoingWeeks(pack?.partial?.weeks)
      setOnGoingPackQuantity(pack?.partial?.numberOfPacks)
    }
  }, [packType])

  useEffect(() => {
    if (isDefaultPlan) {
      setState()
    }
  }, [isDefaultPlan])

  useEffect(() => {
    setPackType(packNew?.default || defaultPack)
    setState()
  }, [packNew, pack, defaultPack])

  useEffect(() => {
    if (prevPackType) {
      if (packNew?.plans.filter((obj: any) => obj.size === prevPackType.replace(/Trial/, '')).length > 0) {
        setPackType(prevPackType)
      }
    }
  }, [prevPackType])

  useEffect(() => {
    setState()
  }, [packType])

  useEffect(() => {
    if (key !== 'plan') {
      navigate(`/signup/${key}/${location.search && '?' + stringify(parse(location.search))}`)
    }
  }, [])

  const setState = () => {
    // TODO: refactor this when more plans available
    if (packType === 'full' && isDefaultPlan) {
      setPackSize(packNew?.plans[0]?.main?.packSize)
      setNoOfPacks(packNew?.plans[0]?.main?.numberOfPacks)
      setWeeks(packNew?.plans[0]?.main?.weeks)
      setPackTypeSelected('Full plan')
      setTrialBoxChecked(false)
    } else if (packType === 'partial' && isDefaultPlan) {
      setPackSize(packNew?.plans[1]?.main?.packSize)
      setNoOfPacks(packNew?.plans[1]?.main?.numberOfPacks)
      setWeeks(packNew?.plans[1]?.main?.weeks)
      setPackTypeSelected('Partial plan')
      setTrialBoxChecked(false)
    } else if (packType === 'fullTrial' && isDefaultPlan) {
      setPackSize(packNew?.plans[0]?.trial?.packSize)
      setNoOfPacks(packNew?.plans[0]?.trial?.numberOfPacks)
      setWeeks(packNew?.plans[0]?.trial?.weeks)
      setPackTypeSelected('Starter box')
      setTrialBoxChecked(true)
    } else if (packType === 'partialTrial' && isDefaultPlan) {
      setPackSize(packNew?.plans[1]?.trial?.packSize)
      setNoOfPacks(packNew?.plans[1]?.trial?.numberOfPacks)
      setWeeks(packNew?.plans[1]?.trial?.weeks)
      setPackTypeSelected('Starter box (partial plan)')
      setTrialBoxChecked(true)
    }
    if (packType === 'FULL' && isDefaultPlan) {
      setPackSize(pack.full.packSize)
      setNoOfPacks(pack.full.numberOfPacks)
      setWeeks(pack.full.weeks)
      setPackTypeSelected('Full plan')
      setTrialBoxChecked(false)
    } else if (packType === 'PARTIAL' && isDefaultPlan) {
      setPackSize(pack.partial.packSize)
      setNoOfPacks(pack.partial.numberOfPacks)
      setWeeks(pack.partial.weeks)
      setPackTypeSelected('Partial plan')
      setTrialBoxChecked(false)
    } else if (packType === 'FULL_TRIAL' && isDefaultPlan) {
      setPackSize(pack.fullTrial.packSize)
      setNoOfPacks(pack.fullTrial.numberOfPacks)
      setWeeks(pack.fullTrial.weeks)
      setPackTypeSelected('Starter box')
      setTrialBoxChecked(true)
    } else if (packType === 'PARTIAL_TRIAL' && isDefaultPlan) {
      setPackSize(pack.partialTrial.packSize)
      setNoOfPacks(pack.partialTrial.numberOfPacks)
      setWeeks(pack.partialTrial.weeks)
      setPackTypeSelected('Starter box (partial plan)')
      setTrialBoxChecked(true)
    }
  }

  const backClick = () => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'clicked_back', {
        page: 'sign_up_plan'
      })
    }

    dispatch(updateKey('your-details'))
    navigate(`/signup/your-details/${location.search && '?' + stringify(parse(location.search))}`)
  }

  useEffect(() => {
    if (changed) {
      dispatch(updateKey('recipes'))
      navigate(`/signup/recipes/${location.search && '?' + stringify(parse(location.search))}`)
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  useEffect(() => {
    if (typeof ttq !== 'undefined') {
      ttq.track('Subscribe')
    }
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'opened_page', {
        page: 'sign_up_plan'
      })
    }
  }, [])

  useEffect(() => {
    // We have to do this because the dogs object contains empty values
    const tempSortedDogInfo: Array<IDog> = []

    dogInfo.dogs.map(dog => {
      if (dog.name) {
        tempSortedDogInfo.push(dog)
      }
    })

    setSortedDogInfo(tempSortedDogInfo)
  }, [])

  useEffect(() => {
    // Add conditional questions to FAQS
    const puppyQuestion = {
      question: `Is Different Dog Suitable for Puppies?`,
      answer: `Yes, we closely follow FEDIAF Guidelines for dog food manufacture, which have tighter tolerances for puppies, meaning 12 of our 13 freshly cooked recipes are suitable for puppies over 8 weeks old (Low Purine & Phosphorous is recommended for adult dogs only). Our highly digestible recipes are rich in high-quality protein and fats, and have the correct ratio of minerals and vitamins to support growth and all-around health from the day they come home.`
    }
    const seniorQuestion = {
      question: `Is Different Dog suitable for senior dogs?`,
      answer: `Each of our 13 delicious recipes is suitable for our precious golden oldies as every pot is packed full of natural anti-ageing antioxidants and cruciferous veggies like broccoli, kale and cabbage, which contain a whole host of goodness, including anti-cancer properties. Senior dogs benefit from high-quality protein as they age, so our recipes, which contain first-cut, 100% British meat, can leave them feeling years younger.`
    }

    const conditionalQuestions = faqs.filter(
      q =>
        q.question !== 'Is Different Dog Suitable for Puppies?' &&
        q.question !== 'Is Different Dog suitable for senior dogs?'
    )
    let puppy = false
    let senior = false

    for (const dog of dogInfo.dogs) {
      if ((dog.dob && !puppy) || !senior) {
        const isDogPuppyOrSenior = checkIfPuppyOrSenior(dog.dob)
        if (isDogPuppyOrSenior === 'puppy' && puppy === false) {
          conditionalQuestions.push(puppyQuestion)
          puppy = true
        }
        if (isDogPuppyOrSenior === 'senior' && senior === false) {
          conditionalQuestions.push(seniorQuestion)
          senior = true
        }
      }
    }

    setFaqList(conditionalQuestions)
  }, [])

  const checkIfPuppyOrSenior = (dob: string) => {
    const todayMinus1Year = dayjs().subtract(1, 'year')
    const todayMinus10Years = dayjs().subtract(8, 'year')
    const dogDob = dayjs(dob)
    if (todayMinus1Year.diff(dogDob, 'year', true) <= 0) return 'puppy'
    if (dogDob.isBefore(todayMinus10Years)) return 'senior'
  }

  const calculateDiscount = (totalValue: number) => {
    if (lastValidatedCoupon.type === 'percentage') {
      return totalValue - (lastValidatedCoupon.amount / 100) * totalValue
    } else {
      return totalValue - lastValidatedCoupon.amount
    }
  }

  const getCostPerDay = () => {
    let totalValue: number = 0
    let costPerDay: number = 0
    let weeks: number = 0
    if (packType === 'full') {
      if (packNew?.plans[0]?.main?.costPerGrams) {
        costPerDay = calculateDiscount(packNew?.plans[0]?.main.costPerGrams)
        if (costPerDay <= 0) {
          return 0
        }
        return costPerDay
      }
      totalValue = packNew?.plans[0]?.main?.totalValue
      weeks = packNew?.plans[0]?.main?.weeks
    } else if (packType === 'partial') {
      totalValue = packNew?.plans[1]?.main?.totalValue
      weeks = packNew?.plans[1]?.main?.weeks
    } else if (packType === 'fullTrial') {
      totalValue = packNew?.plans[0]?.trial?.totalValue
      weeks = packNew?.plans[0]?.trial?.weeks
    } else if (packType === 'partialTrial') {
      totalValue = packNew?.plans[1]?.trial?.totalValue || packNew?.plans[1]?.main?.totalValue
      weeks = packNew?.plans[1]?.trial?.weeks || packNew?.plans[1]?.main?.weeks
    }
    if (packType === 'FULL') {
      if (pack?.full.costPerGrams) {
        costPerDay = calculateDiscount(pack?.full.costPerGrams)
        if (costPerDay <= 0) {
          return 0
        }
        return costPerDay
      }
      totalValue = pack?.full.totalValue
      weeks = pack?.full.weeks
    } else if (packType === 'PARTIAL') {
      totalValue = pack?.partial.totalValue
      weeks = pack?.partial.weeks
    } else if (packType === 'FULL_TRIAL') {
      totalValue = pack?.fullTrial.totalValue
      weeks = pack?.fullTrial.weeks
    } else if (packType === 'PARTIAL_TRIAL') {
      totalValue = pack?.partialTrial.totalValue || pack?.partial.totalValue
      weeks = pack?.partialTrial.weeks || pack?.partial.weeks
    }

    costPerDay = calculateDiscount(totalValue) / (weeks * 7)
    if (costPerDay <= 0) {
      return 0
    }
    return costPerDay
  }

  const getCostPerDayBeforeDiscount = () => {
    let totalValue: number = 0
    let costPerDay: number = 0
    let weeks: number = 0

    if (packType === 'full') {
      if (packNew?.plans[0]?.main?.costPerGrams) {
        return packNew?.plans[0]?.main?.costPerGrams
      }
      totalValue = packNew?.plans[0]?.main?.totalValue
      weeks = packNew?.plans[0]?.main?.weeks
    } else if (packType === 'partial') {
      totalValue = packNew?.plans[1]?.main?.totalValue
      weeks = packNew?.plans[1]?.main?.weeks
    } else if (packType === 'fullTrial') {
      totalValue = packNew?.plans[0]?.trial?.totalValue
      weeks = packNew?.plans[0]?.trial?.weeks
    } else if (packType === 'partialTrial') {
      totalValue = packNew?.plans[1]?.trial?.totalValue || packNew?.plans[1]?.main?.totalValue
      weeks = packNew?.plans[1]?.trial?.weeks || packNew?.plans[1]?.main?.weeks
    }
    if (packType === 'FULL') {
      if (pack?.full.costPerGrams) {
        return pack?.full.costPerGrams
      }
      totalValue = pack?.full.totalValue
      weeks = pack?.full.weeks
    } else if (packType === 'PARTIAL') {
      totalValue = pack?.partial.totalValue
      weeks = pack?.partial.weeks
    } else if (packType === 'FULL_TRIAL') {
      totalValue = pack?.fullTrial.totalValue
      weeks = pack?.fullTrial.weeks
    } else if (packType === 'PARTIAL_TRIAL') {
      totalValue = pack?.partialTrial.totalValue || pack?.partial.totalValue
      weeks = pack?.partialTrial.weeks || pack?.partial.weeks
    }
    costPerDay = totalValue / (weeks * 7)

    return costPerDay
  }

  const getDogNames = () => {
    return sortedDogInfo.map((dog, i) => {
      if (i === sortedDogInfo.length - 1 && sortedDogInfo.length > 1) {
        return ' and ' + dog.name
      }

      if (i > 0) {
        return ', ' + dog.name
      }

      return dog.name
    })
  }

  const getTagline = () => {
    if (!sortedDogInfo.length)
      return `Select from our full or partial feeding plans to give your dog the nutrition they need.`
    const name = sortedDogInfo
      .map(d => d.name)
      .join(', ')
      .replace(/,(?!.*,)/gim, ' &')
    const pronoun =
      sortedDogInfo.length > 1 ? 'they need' : sortedDogInfo[0].gender === 'MALE' ? 'he needs' : 'she needs'
    return `Select from our full or partial feeding plans to give ${name} the nutrition ${pronoun}.`
  }

  return (
    <div className="relative">
      <div className="header-wave-new h-[300px]" />
      <div className="relative">
        <Formik
          innerRef={formikRef}
          initialValues={{
            trialCheckbox: trialBoxChecked,
            packType: prevPackType ? prevPackType : defaultPack
          }}
          validationSchema={PlanDetailSchema}
          onSubmit={values => {
            let planObject = {}
            let planId = `${packSize}g-${weeks}w`

            if (typeof window.gtag !== 'undefined') {
              window.gtag('event', 'clicked_next', {
                page: 'sign_up_plan'
              })
            }
            if (isNewPlan) {
              if (packType === 'full' || packType === 'fullTrial') {
                if (values.trialCheckbox && packNew?.plans[0]?.trial?.costPerDay) {
                  planObject = packNew?.plans[0]?.trial
                  planId = packNew?.plans[0]?.trial?.planId
                } else {
                  planObject = packNew?.plans[0]?.main
                  planId = packNew?.plans[0]?.main?.planId
                }
              } else if (packType === 'partial' || packType === 'partialTrial') {
                if (values.trialCheckbox && packNew?.plans[1]?.trial?.costPerDay) {
                  planObject = packNew?.plans[1]?.trial
                  planId = packNew?.plans[1]?.trial?.planId
                } else {
                  planObject = packNew?.plans[1]?.main
                  planId = packNew?.plans[1]?.main?.planId
                }
              }
            } else {
              if (packType === 'FULL' || packType === 'FULL_TRIAL') {
                if (values.trialCheckbox && pack?.fullTrial.costPerDay) {
                  planObject = pack.fullTrial
                  planId = pack.fullTrial.planId
                } else {
                  planObject = pack.full
                  planId = pack.full.planId
                }
              } else if (packType === 'PARTIAL' || packType === 'PARTIAL_TRIAL') {
                if (values.trialCheckbox && pack?.partialTrial.costPerDay) {
                  planObject = pack.partialTrial
                  planId = pack.partialTrial.planId
                } else {
                  planObject = pack.partial
                  planId = pack.partial.planId
                }
              }
            }
            prevPackType !== packType && dispatch(removeRecipes()) && dispatch(removeAddons())
            prevWeeks !== weeks && dispatch(removeRecipes()) && dispatch(removeAddons())
            prevNoOfPacks !== noOfPacks && dispatch(removeRecipes()) && dispatch(removeAddons())

            dispatch(
              postPlanId({ planId, planObject, noOfPacks, packSize, weeks, isDefaultPlan, packType, fixedOrCustomPlan })
            )
          }}
          validateOnMount
        >
          {props => (
            <div>
              <div className="pt-10 mb-8">
                <h1 data-testid="chooseDogPlan" className="mb-4 text-center text-2xl tracking-tight sm:text-5xl">
                  Choose {getDogNames()}
                  's Plan
                </h1>
                <p className="text-center">{getTagline()}</p>
              </div>
              <div
                className={`mb-16 flex w-full flex-row items-stretch ${
                  (packNew?.plans[0]?.main?.numberOfPacks && packNew?.plans[1]?.main?.numberOfPacks) ||
                  (pack?.full.numberOfPacks && pack?.partial.numberOfPacks)
                    ? `md:max-w-[700px]`
                    : `md:max-w-[350px]`
                } m-auto gap-x-4`}
              >
                {(packNew?.plans[0]?.main?.size === 'full' || pack?.full.packSize) && (
                  <div
                    className={`relative mb-4 flex min-h-full w-full flex-grow flex-col items-center justify-center rounded-3xl p-4 ${
                      packType === 'full' ||
                      packType === 'fullTrial' ||
                      packType === 'FULL' ||
                      packType === 'FULL_TRIAL'
                        ? 'border-4 border-primary bg-ddBlue'
                        : 'border-4 border-transparent bg-ddBlue'
                    }`}
                  >
                    <h3 className="mb-4 text-center text-lg sm:text-3xl">Full Plan</h3>
                    <StaticImage
                      className="mb-4 w-3/6 p-4"
                      alt="Full Diet"
                      src="../../../../images/full-diet-bowl.png"
                    />
                    <p className="mb-4 grow text-center">Feeds all of {getDogNames()}'s needs</p>
                    <div className="mt-4 flex w-full grow flex-row flex-wrap items-end justify-center text-center md:justify-between md:text-left">
                      <p className="mb-4 flex w-full grow flex-col md:mb-0 md:w-1/2">
                        <span>
                          <span className="font-bold">{packNew?.gramsPerDay || pack?.gramsPerDay}g</span> a day
                        </span>
                        <span>
                          <span className="font-bold">
                            {packNew?.plans[0]?.main?.numberOfPacks || pack?.full.numberOfPacks} packs
                          </span>{' '}
                          x{' '}
                          <span className="font-bold">{packNew?.plans[0]?.main?.packSize || pack?.full.packSize}g</span>
                        </span>
                        <span>
                          Every{' '}
                          <span className="font-bold">{packNew?.plans[0]?.main?.weeks || pack?.full.weeks} weeks</span>
                        </span>
                      </p>
                      <label
                        className={`${
                          packType === 'full' ||
                          packType === 'fullTrial' ||
                          packType === 'FULL' ||
                          packType === 'FULL_TRIAL'
                            ? 'btn-primary-active hover:border-secondary hover:bg-secondary focus:border-secondary focus:bg-secondary'
                            : 'btn-primary'
                        }`}
                        onClick={() => {
                          if (typeof window.gtag !== 'undefined') {
                            window.gtag('event', 'selected_full_diet', {
                              page: 'sign_up_plan'
                            })
                          }

                          dispatch(removeRecipes())
                          dispatch(removeAddons())
                          if (isNewPlan) {
                            if (props.values.trialCheckbox) {
                              if (packNew?.plans[0]?.trial?.costPerDay) {
                                setPackType('fullTrial')
                              } else {
                                setPackType('full')
                              }
                            } else {
                              setPackType('full')
                            }
                          } else {
                            if (pack?.fullTrial.costPerDay) {
                              setPackType('FULL_TRIAL')
                            } else {
                              setPackType('FULL')
                            }
                          }
                        }}
                      >
                        {isNewPlan && (
                          <>
                            <Field
                              data-testid="fullDiet"
                              id="fullPlan"
                              type="radio"
                              name="packType"
                              value="full"
                              className="hidden"
                            />
                            {packType === 'full' || packType === 'fullTrial' ? 'Selected' : 'Select'}
                          </>
                        )}
                        {!isNewPlan && (
                          <>
                            <Field
                              data-testid="fullDiet"
                              id="fullPlan"
                              type="radio"
                              name="packType"
                              value="FULL"
                              className="hidden"
                            />
                            {packType === 'FULL' || packType === 'FULL_TRIAL' ? 'Selected' : 'Select'}
                          </>
                        )}
                      </label>
                    </div>
                  </div>
                )}
                {(packNew?.plans[1]?.main?.size === 'partial' || pack?.partial.packSize) && (
                  <div
                    className={`mb-4 flex min-h-full w-full flex-grow flex-col items-center justify-center rounded-3xl bg-white p-4 ${
                      packType === 'partial' ||
                      packType === 'partialTrial' ||
                      packType === 'PARTIAL' ||
                      packType === 'PARTIAL_TRIAL'
                        ? 'border-4 border-primary bg-ddPinkLightest'
                        : 'border-4 border-transparent bg-ddPinkLightest'
                    }`}
                  >
                    <h3 className="mb-4 text-center text-xl sm:text-3xl">Partial Plan</h3>
                    <StaticImage
                      className="mb-4 w-3/6 p-4"
                      alt="Partial Diet"
                      src="../../../../images/partial-diet-bowl.png"
                    />
                    <p className="mb-4 grow text-center">Mix with current food</p>
                    <div className="mt-4 flex w-full grow flex-row flex-wrap items-end justify-center text-center md:justify-between md:text-left">
                      <p className="mb-4 flex w-full grow flex-col md:mb-0 md:w-1/2">
                        <span>
                          <span className="font-bold">
                            {packNew?.plans[1]?.main?.gramsPerDay || pack?.partial.gramsPerDay}g
                          </span>{' '}
                          a day
                        </span>
                        <span>
                          <span className="font-bold">
                            {packNew?.plans[1]?.main?.numberOfPacks || pack?.full.numberOfPacks} packs
                          </span>{' '}
                          x{' '}
                          <span className="font-bold">{packNew?.plans[1]?.main?.packSize || pack?.full.packSize}g</span>
                        </span>
                        <span>
                          Every{' '}
                          <span className="font-bold">
                            {packNew?.plans[1]?.main?.weeks || pack?.partial.weeks} weeks
                          </span>
                        </span>
                      </p>
                      <label
                        data-testid="partialDietButton"
                        className={`${
                          packType === 'partial' ||
                          packType === 'partialTrial' ||
                          packType === 'PARTIAL' ||
                          packType === 'PARTIAL_TRIAL'
                            ? 'btn-primary-active hover:border-secondary hover:bg-secondary focus:border-secondary focus:bg-secondary'
                            : 'btn-primary'
                        }`}
                        onClick={() => {
                          if (typeof window.gtag !== 'undefined') {
                            window.gtag('event', 'selected_partial_diet', {
                              page: 'sign_up_plan'
                            })
                          }

                          dispatch(removeRecipes())
                          dispatch(removeAddons())

                          if (isNewPlan) {
                            if (props.values.trialCheckbox) {
                              if (packNew?.plans[1]?.trial?.costPerDay) {
                                setPackType('partialTrial')
                              } else {
                                setPackType('partial')
                              }
                            } else {
                              setPackType('partial')
                            }
                          } else {
                            if (pack?.fullTrial.costPerDay) {
                              setPackType('PARTIAL_TRIAL')
                            } else {
                              setPackType('PARTIAL')
                            }
                          }
                        }}
                      >
                        {isNewPlan && (
                          <>
                            <Field
                              data-testid="partialDiet"
                              type="radio"
                              name="packType"
                              value="partial"
                              className="hidden"
                            />
                            {packType === 'partial' || packType === 'partialTrial' ? 'Selected' : 'Select'}
                          </>
                        )}
                        {!isNewPlan && (
                          <>
                            <Field
                              data-testid="partialDiet"
                              type="radio"
                              name="packType"
                              value="PARTIAL"
                              className="hidden"
                            />
                            {packType === 'PARTIAL' || packType === 'PARTIAL_TRIAL' ? 'Selected' : 'Select'}
                          </>
                        )}
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`m-auto mb-4 w-full md:w-2/3 ${
                  ((packType === 'partialTrial' || packType === 'PARTIAL_TRIAL') &&
                    (packNew?.plans[1]?.trial?.costPerDay || pack?.partialTrial.costPerDay)) ||
                  ((packType === 'fullTrial' || packType === 'FULL_TRIAL') &&
                    (packNew?.plans[0]?.trial?.costPerDay || pack?.fullTrial.costPerDay)) ||
                  ((packType === 'partial' || packType === 'PARTIAL') &&
                    (packNew?.plans[1]?.trial?.costPerDay || pack?.partialTrial.costPerDay)) ||
                  ((packType === 'full' || packType === 'FULL') &&
                    (packNew?.plans[0]?.trial?.costPerDay || pack?.fullTrial.costPerDay))
                    ? 'block'
                    : 'hidden'
                }`}
              >
                <div className="relative mb-2 flex bg-background pb-24 lg:pb-0">
                  <div className="p-4" style={{ minWidth: '60%' }}>
                    <h2 className="mb-2 text-left">
                      <span className="text-xl">We'll send you a</span>{' '}
                      <span className="text-2xl sm:text-3xl">Starter Box</span>
                    </h2>
                    <p className="mb-4 text-sm sm:text-lg">
                      To help your dog transition onto our food, you'll get a smaller amount to begin with.
                    </p>
                    <p className="mb-0 text-sm sm:text-lg">
                      <span className="font-bold">
                        {noOfPacks} x {packSize}g
                      </span>{' '}
                      packs to start.
                    </p>
                    <p className="mb-4 text-sm sm:text-lg">
                      Followed by{' '}
                      <span className="font-bold">
                        {onGoingPackQuantity} x {onGoingPackSize}g
                      </span>{' '}
                      packs every {onGoingWeeks} weeks.
                    </p>
                    <p className="mb-4 text-left text-xs">You can amend, pause or cancel your subscription anytime.</p>
                    <label>
                      <Field
                        data-testid="trialCheckbox"
                        type="checkbox"
                        name="trialCheckbox"
                        id="trialCheckbox"
                        checked={trialBoxChecked}
                        className="text-fourth-checkbox"
                        onChange={e => {
                          dispatch(updateTrialBox(e.target.checked))

                          dispatch(removeRecipes())
                          dispatch(removeAddons())

                          if (e.target.checked) {
                            if (typeof window.gtag !== 'undefined') {
                              window.gtag('event', 'check_starter_box', {
                                page: 'sign_up_plan'
                              })
                            }
                            if (isNewPlan) {
                              if (packType === 'partial') {
                                if (packNew?.plans[1]?.trial?.costPerDay) {
                                  setPackType('partialTrial')
                                }
                              } else if (packType === 'full') {
                                if (packNew?.plans[0]?.trial?.costPerDay) {
                                  setPackType('fullTrial')
                                }
                              }
                            } else {
                              if (packType === 'PARTIAL') {
                                if (pack?.partialTrial.costPerDay) {
                                  setPackType('PARTIAL_TRIAL')
                                }
                              } else if (packType === 'FULL') {
                                if (pack?.fullTrial.costPerDay) {
                                  setPackType('FULL_TRIAL')
                                }
                              }
                            }
                          } else {
                            if (typeof window.gtag !== 'undefined') {
                              window.gtag('event', 'uncheck_starter_box', {
                                page: 'sign_up_plan'
                              })
                            }
                            if (isNewPlan) {
                              if (packType === 'partialTrial') {
                                setPackType('partial')
                              } else if (packType === 'fullTrial') {
                                setPackType('full')
                              }
                            } else {
                              if (packType === 'PARTIAL_TRIAL') {
                                setPackType('PARTIAL')
                              } else if (packType === 'FULL_TRIAL') {
                                setPackType('FULL')
                              }
                            }
                          }
                          formikRef.current.setFieldValue('trialCheckbox', e.target.checked)
                        }}
                      />
                      <span className="ml-2 font-bold">Send me a starter box</span>
                    </label>
                  </div>
                  <div className="hidden w-full md:relative lg:block" style={{ right: '-4rem' }}>
                    <StaticImage
                      objectFit="contain"
                      src="../../../../images/starter-box.png"
                      className="relative w-full grow"
                      width={250}
                      alt="Your starter box"
                      style={{
                        top: '-1rem',
                        right: '-1rem'
                      }}
                    />
                  </div>
                </div>
                <div className="w-full lg:hidden">
                  <StaticImage
                    objectFit="contain"
                    src="../../../../images/starter-box.png"
                    className="relative w-full grow"
                    width={150}
                    alt="Your starter box"
                    style={{
                      top: '-6rem',
                      marginBottom: '-6rem'
                    }}
                  />
                </div>
              </div>

              <FaqAccordion
                faqs={faqList}
                backgroundColor={'#A6C7D1'}
                headingText={'FAQs'}
                headingTextColor={'primary'}
                faqQuestionColor={'primary'}
                faqAnswerColor={'primary'}
                headingType={HeadingType.P}
                headingClass={HeadingClass.H3}
                iconClass={'text-white'}
                // borderClass={'red'}
              />
              <div className="footer-wave-bottom-signup relative -mb-32 py-16">
                <div className="mb-4 w-full text-center font-serif">
                  <h3 className="mb-8">Have you got any questions?</h3>
                  <p>
                    Call us on{' '}
                    <a className="font-bold" href="tel:+441743384562">
                      01743 384 562
                    </a>{' '}
                    or email{' '}
                    <a className="font-bold" href="mailto:feedme@differentdog.com">
                      feedme@differentdog.com
                    </a>{' '}
                  </p>
                </div>
                <TrustPilotMini backgroundColor="transparent" />
              </div>

              <StickyFooter className="fixed bottom-0 left-0 right-0 z-[3] z-auto ml-auto max-h-20 bg-white shadow-[0_-5px_15px_rgba(0,0,0,0.25)]">
                <div className="flex w-full flex-col">
                  <div className="mb-4 w-full text-center">
                    <p className="relative top-4 mx-auto md:right-16 xl:top-0">
                      <span className="relative top-4 z-10 text-xs md:text-base">
                        <span>{packTypeSelected}</span> from{' '}
                        <span data-testid="totalPrice" className="strikethrough">
                          £{(getCostPerDayBeforeDiscount() / 100)?.toFixed(2)}
                        </span>{' '}
                        <span className="font-bold">
                          <span className="totalPrice">£{(getCostPerDay() / 100)?.toFixed(2)}</span> per day
                        </span>
                      </span>
                    </p>
                  </div>

                  <div className="relative -top-20 flex w-full items-center justify-between">
                    <button
                      data-testid="planBack"
                      onClick={backClick}
                      type="button"
                      className="rounded-full border border-primary bg-white px-4 py-2 shadow-md hover:underline focus:underline"
                    >
                      Back
                    </button>

                    <button
                      data-testid="planBtn"
                      onClick={() => props.handleSubmit()}
                      disabled={!props.isValid || loading}
                      type="button"
                      className={`${
                        !props.isValid || loading ? '!bg-[#80B2C2]' : ''
                      } btn-cta scale-animation !px-8 !py-4 !text-xl !font-bold shadow-[0_-5px_15px_rgba(0,0,0,0.25)] sm:!px-16`}
                    >
                      {loading ? (
                        <div className="align-center flex justify-center">
                          <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                          <div>Saving</div>
                        </div>
                      ) : (
                        'Next'
                      )}
                    </button>
                  </div>
                </div>
              </StickyFooter>
            </div>
          )}
        </Formik>
      </div>
      {/* <FeedbackForm source={'signup'} /> */}
    </div>
  )
}

export default Plan
